import { Icon, IconType, Tooltip } from '@adsk/alloy-react';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { TabProgress } from '../../types';
import { MIDTabIcon, MIDTabTooltipWrapper, StyledMIDTab } from './MIDTabs.styles';
import text from '../../../Common/global/text/text.json';

export interface MIDTabProps {
  children: ReactElement<any, string | JSXElementConstructor<any>> | string;
  label: string;
  id: string;
  tabProgress: TabProgress;
  disabled?: boolean;
  className?: string;
  handleTabClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

interface TabProgressIconsMap {
  [TabProgress.COMPLETED]: IconType;
  [TabProgress.PARTIALLY_COMPLETED]: IconType;
  [TabProgress.EMPTY]: IconType;
}
interface TooltipContentMap {
  [TabProgress.COMPLETED]: string;
  [TabProgress.PARTIALLY_COMPLETED]: string;
  [TabProgress.EMPTY]: string;
}

const MIDTab: React.FC<MIDTabProps> = ({
  children,
  label,
  id,
  tabProgress,
  disabled,
  className,
  handleTabClick,
}) => {
  const tabProgressIconsMap: TabProgressIconsMap = {
    [TabProgress.COMPLETED]: Icon.TYPES.CHECKMARK_CIRCLE_FILLED,
    [TabProgress.PARTIALLY_COMPLETED]: Icon.TYPES.CONTRAST,
    [TabProgress.EMPTY]: Icon.TYPES.CONTRAST,
  };
  const tooltipContentMap: TooltipContentMap = {
    [TabProgress.COMPLETED]: text.tabRequiredFieldsCompleted,
    [TabProgress.PARTIALLY_COMPLETED]: text.tabRequiredFieldsMissing,
    [TabProgress.EMPTY]: text.tabRequiredFieldsMissing,
  };

  return (
    <StyledMIDTab
      label={label}
      id={id}
      data-testid={`midtab-${id}`}
      className={className}
      onClick={handleTabClick}
    >
      {children}
      {!disabled && (
        <MIDTabTooltipWrapper>
          <Tooltip content={tooltipContentMap[tabProgress]}>
            <MIDTabIcon type={tabProgressIconsMap[tabProgress]} fr={0} />
          </Tooltip>
        </MIDTabTooltipWrapper>
      )}
    </StyledMIDTab>
  );
};

export default MIDTab;
