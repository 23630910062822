import { Button } from '@adsk/alloy-react';
import React from 'react';
import { MIDFormField } from '../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../Common/global/text/text.json';
import { SourceContentRightButton, SourceContentTextInput } from '../SourceContentTab.styles';

interface IPJSectionProps {
  iPJ: string;
  handleSelectInventorProjectClick: () => Promise<void>;
}

const IPJSection: React.FC<IPJSectionProps> = ({
  iPJ,
  handleSelectInventorProjectClick,
}): JSX.Element => (
  <MIDFormField label={text.sourceContentIPJLabel} labelVariant="top">
    <SourceContentTextInput
      data-testid="ipj-input"
      readOnly
      type="text"
      placeholder="Not selected"
      value={iPJ}
    />
    {iPJ ? (
      <SourceContentRightButton
        data-testid="ipj-replace-button"
        onClick={handleSelectInventorProjectClick}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonReplace}
      </SourceContentRightButton>
    ) : (
      <SourceContentRightButton
        data-testid="ipj-select-button"
        onClick={handleSelectInventorProjectClick}
        variant={Button.VARIANTS.PRIMARY}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonSelect}
      </SourceContentRightButton>
    )}
  </MIDFormField>
);

export default IPJSection;
