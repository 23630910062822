import { ICON_TYPES } from '@adsk/alloy-react';
import { Column } from '@adsk/alloy-react-table';
import React, { useEffect, useState } from 'react';
import {
  DraftSelectionTable,
  DraftTableWrapper,
  DraftTemplateTableHeader,
  DraftTemplateTableSummary,
  SelectedText,
  TableHeaderButton,
} from './DraftTable.styles';
import text from '../../../Common/global/text/text.json';
import useDraftTable from './useDraftTable';
import { DraftTemplate } from '../../../lib/interfaces/templates';
import { getColumns } from './DraftTable.utils';
import { debounce } from 'lodash';

export interface DraftTableProps {
  drafts: DraftTemplate[] | null;
  isFetching: boolean;
  handleEditTemplateClick: (draftTemplate: DraftTemplate) => void;
  handleDeleteTemplatesClick: (draftIds: string[]) => void;
}

const DraftTable: React.FC<DraftTableProps> = ({
  drafts,
  isFetching,
  handleEditTemplateClick,
  handleDeleteTemplatesClick,
}): JSX.Element => {
  const {
    tableData,
    selectedDraftIds,
    numberOfDraftsSelected,
    setTableOptions,
    handleEditTemplateClickWithContext,
    handleDeleteTemplateClickWithContext,
    onAction,
    renderEmptyState,
  } = useDraftTable({
    drafts,
    isFetching,
    handleEditTemplateClick,
    handleDeleteTemplatesClick,
  });
  const [columns, setColumns] = useState(getColumns());

  useEffect(() => {
    const windowResizeCallback = () => {
      setColumns(getColumns());
    };
    const debouncedWindowResizeCallback = debounce(windowResizeCallback, 300);

    window.addEventListener('resize', debouncedWindowResizeCallback);

    return () => window.removeEventListener('resize', debouncedWindowResizeCallback);
  });

  return (
    <DraftTableWrapper>
      <DraftTemplateTableHeader>
        {drafts && drafts.length > 0 && (
          <DraftTemplateTableSummary data-testid="draft-template-table-summary">
            <b>{text.draftTableSummaryProductDefinitions}</b> ({drafts.length}{' '}
            {text.draftTableSummaryInList})
          </DraftTemplateTableSummary>
        )}
        <SelectedText data-testid="selected-text">
          {Object.keys(selectedDraftIds).length} {text.draftTableSelected}
        </SelectedText>
        <TableHeaderButton
          disabled={numberOfDraftsSelected !== 1}
          icon={ICON_TYPES.PENCIL}
          onClick={handleEditTemplateClickWithContext}
        >
          {text.draftTableEdit}
        </TableHeaderButton>
        <TableHeaderButton
          disabled={numberOfDraftsSelected === 0}
          icon={ICON_TYPES.TRASH_CAN}
          onClick={handleDeleteTemplateClickWithContext}
        >
          {text.draftTableDelete}
        </TableHeaderButton>
      </DraftTemplateTableHeader>
      <DraftSelectionTable
        columns={columns as Column<Record<string, unknown>>[]}
        data={tableData as Record<string, any>[]}
        isMultiSelectable
        isResizable
        isSortable
        selectedRowIds={selectedDraftIds}
        setTableOptions={setTableOptions}
        onAction={onAction}
        renderEmptyState={renderEmptyState}
      />
    </DraftTableWrapper>
  );
};

export default DraftTable;
