import { Icon } from '@adsk/alloy-react';
import React from 'react';
import text from '../../../../../Common/global/text/text.json';
import { DownloadIcon, FileUpload, TablesDescriptionBox, TextWrapper } from '../TablesTab.styles';
import TableUpload from './TableUpload';

interface UploadBox {
  tableFile: string;
  handleNextClick: () => void;
  handleDeleteClick: () => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadBox: React.FC<UploadBox> = ({
  tableFile,
  handleDeleteClick,
  handleNextClick,
  handleFileSelect,
}): JSX.Element => (
  <TablesDescriptionBox>
    <TextWrapper>
      <strong>{text.useDependencyTables}</strong> {text.dependencyTablesDescription}
      <TextWrapper>
        {text.createDependencyTables}
        <TextWrapper>
          {/* TODO: Add table guide link when available */}
          {text.download}{' '}
          <a href="#">
            {' '}
            {text.multiValueTableGuidelines}{' '}
            <DownloadIcon type={Icon.TYPES.CLOUD_DOWN_ARROW} fr={0} size={18} />
          </a>{' '}
          {text.followInstructions}
        </TextWrapper>
      </TextWrapper>
    </TextWrapper>
    <FileUpload>
      <p>{text.importSpreadsheet}</p>
      <TextWrapper>
        <TableUpload
          tableName={tableFile}
          handleDeleteClick={handleDeleteClick}
          handleNextClick={handleNextClick}
          handleFileSelect={handleFileSelect}
        />
        <p>{text.onlyXLSformatSupported}</p>
      </TextWrapper>
    </FileUpload>
  </TablesDescriptionBox>
);

export default UploadBox;
