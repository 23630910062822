import React, { Children } from 'react';
import MIDTab, { MIDTabProps } from './MIDTab';
import { MIDTabsWrapper } from './MIDTabs.styles';

interface MIDTabsProps {
  activeTab: string;
}

const MIDTabs: React.FC<MIDTabsProps> = ({ children, activeTab }): JSX.Element => {
  let activeChildren;

  // Creating the <li> elements of the Tabs
  const tabs: JSX.Element[] = Children.map(
    children as React.ReactElement<MIDTabProps>[],
    (child: React.ReactElement<MIDTabProps>) => {
      if (child) {
        if (child.props.id === activeTab) {
          // Saving the active children
          activeChildren = child.props.children;

          return (
            <MIDTab
              className="active"
              id={child.props.id}
              label={child.props.label}
              handleTabClick={child.props.handleTabClick}
              tabProgress={child.props.tabProgress}
            >
              {child.props.label}
            </MIDTab>
          );
        }

        return (
          <MIDTab
            id={child.props.id}
            label={child.props.label}
            handleTabClick={child.props.handleTabClick}
            tabProgress={child.props.tabProgress}
            {...(child.props.disabled && { className: 'disabled', disabled: child.props.disabled })}
          >
            {child.props.label}
          </MIDTab>
        );
      }
    },
  );

  // Rendering Tabs (<ul> <li>s </ul>) and the Component the should be Active
  return (
    <>
      <MIDTabsWrapper>{tabs}</MIDTabsWrapper>
      {activeChildren}
    </>
  );
};

export default MIDTabs;
