import { Icon } from '@adsk/alloy-react';
import styled, { css } from 'styled-components';

export const ItemCount = styled.p`
  // TODO: Can't use var because var includes padding
  height: 20px;
  margin: ${({ theme }) => `${theme.vars.marginBase * 1.4}px`};
  ${({ theme }) => theme.typography.bodyMedium}
  text-align: right;
`;

export const EditInputsContainer = styled.form`
  margin-top: ${({ theme }) => `${theme.vars.marginBase}px`};
  overflow: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.editInputsContainerHeight}px`}
  );
`;

export const CodeblocksButtonContainer = styled.div`
  display: flex;
  margin: 0 ${({ theme }) => `${theme.vars.mainMargin}px`};
  justify-content: flex-end;
`;

export const EnableDisableFormWrapper = styled.fieldset`
  all: inherit;
  ${(props) => props.disabled && disabledFormCSS}
`;

const disabledFormCSS = css`
  opacity: ${({ theme }) => theme.vars.formDisabledOpacity};
  pointer-events: none;
  events: none;
`;

export const MoveUpDownIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.black};
`;
