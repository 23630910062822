import React from 'react';
import EditInputs from './EditInputs/EditInputsView';
import { useInputsTab } from './useInputsTab';
import InputsSelection from './InputsSelection/InputsSelection';
import { useTransformToTableIds } from './hooks/useTransformToTableIds';
import { TabContainer } from '../../../../Common/global/styles/Common/Common.styles';

const InputsTab: React.FC = (): JSX.Element => {
  const {
    isEditStep,
    inventorData,
    selectedParameters,
    selectedIProperties,
    setIsEditStep,
    setSelectedParameters,
    setSelectedIProperties,
    handleSelectedInputDataStoreUpdate,
    handleAddInputsButtonClick,
    loading,
  } = useInputsTab();
  const selectedParameterIds = useTransformToTableIds(selectedParameters);
  const selectedIPropertyIds = useTransformToTableIds(selectedIProperties);

  return (
    <TabContainer>
      {!isEditStep ? (
        <InputsSelection
          inventorData={inventorData}
          selectedParameterIds={selectedParameterIds}
          selectedIPropertyIds={selectedIPropertyIds}
          setSelectedParameters={setSelectedParameters}
          setSelectedIProperties={setSelectedIProperties}
          handleAddInputsButtonClick={handleAddInputsButtonClick}
          isFetching={loading}
        />
      ) : (
        <EditInputs
          selectedParameters={selectedParameters}
          selectedIProperties={selectedIProperties}
          setSelectedParameters={setSelectedParameters}
          setIsEditStep={setIsEditStep}
          handleSelectedInputDataStoreUpdate={handleSelectedInputDataStoreUpdate}
        />
      )}
    </TabContainer>
  );
};

export default InputsTab;
