import { Icon, TextInput } from '@adsk/alloy-react';
import styled from 'styled-components';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';
import DataGrid from 'react-data-grid';

export const TablesDescriptionBox = styled.div`
  background-color: ${({ theme }) => theme.colors.charcoal100};
  width: 40%;
  min-width: ${({ theme }) => `${theme.vars.tableUploadBox}px`};
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
`;

export const TextWrapper = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`}
    ${({ theme }) => `${theme.vars.paddingBase * 2.5}px`} 0;
`;

export const WarningDialog = styled.div`
  background-color: ${({ theme }) => theme.colors.adskBlue100};
  margin: ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
  text-align: center;
`;

export const DownloadIcon = styled(Icon)`
  vertical-align: top;
`;

export const FileUpload = styled.div`
  background-color: ${({ theme }) => theme.colors.adskBlue100};
  margin: ${({ theme }) => `${theme.vars.marginBase * 2}px`};
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const TableTextInput = styled(TextInput)`
  width: 300px;
  display: inline-block;
  vertical-align: top;
`;

export const TableRightButton = styled(RightButton)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
  margin-bottom: 4px; // To better alignment with input
  vertical-align: bottom;
`;

export const DataGridWrapper = styled(DataGrid)`
  overflow: auto;
  height: fit-content;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.editSpreadsheetContainerHeight}px`}
  );
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase * 2}px`};
`;

export const TableEditWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.charcoal100};
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const InstructionsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.charcoal200};
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const TableSmallTitle = styled.h4`
  ${({ theme }) => theme.typography.heading4}
  padding-bottom: ${({ theme }) => `${theme.vars.paddingBase / 2}px`};
  margin: 0;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FormWrapper = styled.form`
  display: inline;
`;
