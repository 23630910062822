import React from 'react';
import { iPropertiesTableColumns, parameterTableColumns } from './constants';
import InputsHeader from './InputsHeader';
import InputsTable from './InputsTable';
import { SetSelectedIProperties, SetSelectedParameters } from './InputsSelection.types';
import { useInputsHeader } from './useInputsHeader';
import { useInputsTable } from './useInputsTable';
import { filterIProperties, filterParameters } from './utils';
import { InventorProperties } from '../../../../../lib/interfaces/inventorProperties';
import { SelectedIdsMap } from '../hooks/useTransformToTableIds';

export interface InputsSelectionProp {
  inventorData: InventorProperties | null;
  selectedParameterIds: SelectedIdsMap;
  selectedIPropertyIds: SelectedIdsMap;
  setSelectedParameters: SetSelectedParameters;
  setSelectedIProperties: SetSelectedIProperties;
  handleAddInputsButtonClick: () => void;
  isFetching: boolean;
}

const InputsSelection: React.FC<InputsSelectionProp> = ({
  inventorData,
  selectedParameterIds,
  selectedIPropertyIds,
  setSelectedParameters,
  setSelectedIProperties,
  handleAddInputsButtonClick,
  isFetching,
}): JSX.Element => {
  const {
    buttonGroupValue,
    handleOnParameterOriPropertyButtonChange,
    currentFilter,
    setCurrentFilter,
    filterItems,
    isParameter,
  } = useInputsHeader();
  const totalSelected =
    Object.keys(selectedParameterIds).length + Object.keys(selectedIPropertyIds).length;

  const { renderEmptyiPropertiesState, ...parametersTableProps } = useInputsTable({
    // Initialize the table data
    initialTableData: inventorData ? inventorData.parameters : null,
    // The key in each table data used as table rowId
    rowIdKey: 'name',
    // table columns
    tableColumns: parameterTableColumns,
    // Used to help generate handleOnAction: when user toggle the checkbox in table
    setSelectedIds: setSelectedParameters,
    // Used by filterParameters function to filter data
    selectedIds: selectedParameterIds,
    // Used by filterParameters function to filter data
    currentFilter,
    // The function used to filter data
    filterFunc: filterParameters,
    // flag to tell if data is still being fetched
    isFetching,
  });

  const { renderEmptyParametersState, ...iPropertiesTableProps } = useInputsTable({
    // Initialize the table data
    initialTableData: inventorData ? inventorData.iProperties : null,
    // The key in each table data used as table rowId
    rowIdKey: 'id',
    // table columns
    tableColumns: iPropertiesTableColumns,
    // Used to help generate handleOnAction: when user toggle the checkbox in table
    setSelectedIds: setSelectedIProperties,
    // The function used to filter data
    filterFunc: filterIProperties,
    // Used by filterIProperties function to filter data
    selectedIds: selectedIPropertyIds,
    // Used by filterIProperties function to filter data
    currentFilter,
    // flag to tell if data is still being fetched
    isFetching,
  });

  return (
    <>
      <InputsHeader
        buttonGroupValue={buttonGroupValue}
        handleOnParameterOriPropertyButtonChange={handleOnParameterOriPropertyButtonChange}
        filterItems={filterItems}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        totalSelected={totalSelected}
        handleAddInputsButtonClick={handleAddInputsButtonClick}
      />
      {isParameter ? (
        <InputsTable
          {...parametersTableProps}
          renderEmptyState={parametersTableProps.renderEmptyParametersState}
        />
      ) : (
        <InputsTable
          {...iPropertiesTableProps}
          renderEmptyState={iPropertiesTableProps.renderEmptyiPropertiesState}
        />
      )}
    </>
  );
};

export default InputsSelection;
