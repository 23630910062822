import React, { useEffect, useState } from 'react';
import text from '../../../Common/global/text/text.json';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';

interface UseBooleanInput {
  localTrueLabel: string;
  localFalseLabel: string;
  handleInputTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface UseBooleanArgs {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  trueLabel: string;
  falseLabel: string;
}

const useBooleanInput = ({
  callInputDataStoreUpdateHandler,
  trueLabel,
  falseLabel,
}: UseBooleanArgs): UseBooleanInput => {
  const [localTrueLabel, setLocalTrueLabelValue] = useState(trueLabel);
  const [localFalseLabel, setLocalFalseLabelValue] = useState(falseLabel);

  useEffect(() => {
    setLocalTrueLabelValue(trueLabel);
    setLocalFalseLabelValue(falseLabel);
  }, [trueLabel, falseLabel]);

  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const value = event.target.value;

    if (inputName === text.inputLabelBooleanTrue) {
      setLocalTrueLabelValue(value);
      callInputDataStoreUpdateHandler({ trueLabel: value }, true);
    } else {
      setLocalFalseLabelValue(value);
      callInputDataStoreUpdateHandler({ falseLabel: value }, true);
    }
  };

  return {
    localTrueLabel,
    localFalseLabel,
    handleInputTextChange,
  };
};

export default useBooleanInput;
