import { Icon, Tooltip } from '@adsk/alloy-react';
import { Column, Table } from '@adsk/alloy-react-table';
import React from 'react';
import MultiValueList from '../../../../../../Common/components/MultiValueList/MultiValueList';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  RowItem,
  TooltipIcon,
  TooltipWrapper,
} from '../../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../../Common/global/text/text.json';
import { RuleTableModel } from '../EditInputs.types';

export interface DependencyRulesProps {
  items: string[];
  value: string;
  dependencyRuleTableData: RuleTableModel[];
  dependencyRuleTableColumns: Column<RuleTableModel>[];
  handleSelectControllingInput: (newValue: string | number) => void;
}
const DependencyRules: React.FC<DependencyRulesProps> = ({
  items,
  value,
  dependencyRuleTableColumns,
  dependencyRuleTableData,
  handleSelectControllingInput,
}): JSX.Element => (
  <FieldSetContainer>
    <FieldsetRow>
      <RowItem>
        <b>
          {value !== text.none ? 2 : text.no} {text.inventorNoBooleanDependencyRules}
        </b>
        <TooltipWrapper>
          <Tooltip
            content={
              <>
                <h3>{text.inventorBooleanDependencyRuleTooltipHeader}</h3>
                <p>{text.inventorBooleanDependencyRuleTooltipSummary}</p>
                <p>{text.inventorBooleanDependencyRuleTooltipDetails}</p>
              </>
            }
          >
            <TooltipIcon type={Icon.TYPES.QUESTION_CIRCLE_FILLED} size={17} fr={0} />
          </Tooltip>
        </TooltipWrapper>
      </RowItem>
    </FieldsetRow>
    <FieldsetRow>
      <RowItem>
        <InputFormField
          label={text.inventorControllingParameter}
          labelVariant={'top'}
          width={'100%'}
        >
          <MultiValueList
            items={items}
            value={value}
            readOnly={false}
            onSelectionChange={handleSelectControllingInput}
          />
        </InputFormField>
      </RowItem>
    </FieldsetRow>
    <FieldsetRow>
      <RowItem>
        {value !== text.none && (
          <Table
            columns={dependencyRuleTableColumns as Column<Record<string, unknown>>[]}
            data={dependencyRuleTableData as Record<string, any>[]}
          />
        )}
      </RowItem>
    </FieldsetRow>
  </FieldSetContainer>
);

export default DependencyRules;
