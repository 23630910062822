import React from 'react';
import { OutputContainer } from './OutputsTab.styles';
import OutputRepresentations from './OutputRepresentations';
import { shopDrawingOutputRepresentations } from './constants';

const ShopDrawingOutput: React.FC = (): JSX.Element => (
  <OutputContainer>
    <OutputRepresentations
      representations={shopDrawingOutputRepresentations}
      selectedRepresentations={[]}
    />
  </OutputContainer>
);

export default ShopDrawingOutput;
