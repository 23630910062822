import React, { useContext } from 'react';
import {
  OutputHeader,
  OutputInputControl,
  OutputRow,
  OutputSmallText,
  OutputTextInput,
} from './OutputsTab.styles';
import text from '../../../../Common/global/text/text.json';
import { TooltipIcon, TooltipWrapper } from '../../../../Common/global/styles/Common/Common.styles';
import { ICON_TYPES, Tooltip } from '@adsk/alloy-react';
import Dropdown from '../../../../Common/components/Dropdown/Dropdown';
import { DropdownItem } from '../../../../Common/components/Dropdown/Dropdown.types';
import { ThemeContext } from 'styled-components';

interface RevitClassificationSectionProps {
  revitFamilyCategoryList: DropdownItem[];
  selectedCategory: DropdownItem | null;
  revitFamily: string;
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RevitClassificationSection: React.FC<RevitClassificationSectionProps> = ({
  revitFamilyCategoryList,
  selectedCategory,
  revitFamily,
  handleOnSelectCategory,
  handleRevitFamilyChange,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <OutputRow>
        <OutputHeader>
          <strong>{text.outputRevitClassification}</strong>
          <TooltipWrapper>
            <Tooltip content={text.outputRevitClassificationTooltip}>
              <TooltipIcon type={ICON_TYPES.ALERT_INFORMATION_FILLED} size={17} fr={0} />
            </Tooltip>
          </TooltipWrapper>
        </OutputHeader>
      </OutputRow>
      <OutputRow>
        <OutputInputControl>
          <Dropdown
            elements={revitFamilyCategoryList}
            title={text.revitFamilyCategoryMandatory}
            selectedItem={selectedCategory}
            onSelect={handleOnSelectCategory}
            width={theme.vars.wideInputControl}
          />
        </OutputInputControl>
        <OutputInputControl>
          <label>{text.revitFamilyLable}</label>
          <OutputTextInput type="text" value={revitFamily} onChange={handleRevitFamilyChange} />
        </OutputInputControl>
      </OutputRow>
      <OutputRow>
        <OutputSmallText>{text.revitFamilyCategoryMandatoryExplanation}</OutputSmallText>
      </OutputRow>
    </>
  );
};

export default RevitClassificationSection;
