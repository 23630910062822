import { Button, ICON_TYPES, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import {
  Label,
  LabelText,
  LabelTextRequired,
  TooltipIcon,
  TooltipWrapper,
} from '../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../Common/global/text/text.json';
import { SourceContentRightButton, SourceContentTextInput } from '../SourceContentTab.styles';

interface TopFolderSectionProps {
  topLevelFolder: string;
  handleSelectTopLevelFolderClick: () => Promise<void>;
}

const TopFolderSection: React.FC<TopFolderSectionProps> = ({
  topLevelFolder,
  handleSelectTopLevelFolderClick,
}): JSX.Element => (
  <Label>
    <LabelText>
      {text.sourceContentTopLevelFolderLabel}
      <LabelTextRequired>*</LabelTextRequired>
      <TooltipWrapper>
        <Tooltip content={text.sourceContentTopLevelTooltip}>
          <TooltipIcon type={ICON_TYPES.ALERT_INFORMATION_FILLED} fr={0} />
        </Tooltip>
      </TooltipWrapper>
    </LabelText>
    <SourceContentTextInput
      type="text"
      placeholder="Not selected"
      data-testid="top-folder-input"
      readOnly
      defaultValue={topLevelFolder}
    />
    {topLevelFolder ? (
      <SourceContentRightButton
        data-testid="top-folder-replace-button"
        onClick={handleSelectTopLevelFolderClick}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonReplace}
      </SourceContentRightButton>
    ) : (
      <SourceContentRightButton
        data-testid="top-folder-select-button"
        onClick={handleSelectTopLevelFolderClick}
        variant={Button.VARIANTS.PRIMARY}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonSelect}
      </SourceContentRightButton>
    )}
  </Label>
);

export default TopFolderSection;
