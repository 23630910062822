import React from 'react';
import { AccordionItem } from '@adsk/alloy-react';
import BillOfMaterialsOutput from './BillOfMaterialsOutput';
import InventorDrawingOutput from './InventorDrawingOutput';
import InventorModelOutput from './InventorModelOutput';
import NeutralFormatOutput from './NeutralFormatOutput';
import RevitFamilyOutput from './RevitFamilyOutput';
import ShopDrawingOutput from './ShopDrawingOutput';
import { AccordianItemLabel, AccordianItemLabelGroup, AccordionWrap } from './OutputsTab.styles';
import text from '../../../../Common/global/text/text.json';
import { TabFormContainer } from '../../../../Common/global/styles/Common/Common.styles';

const OutputsTab: React.FC = (): JSX.Element => (
  <TabFormContainer>
    <AccordionWrap multiExpand initialExpanded={[0, 1]}>
      <AccordionItem
        title={<h3>{text.outputsRevitFamily} *</h3>}
        label={<AccordianItemLabel>{text.outputsRFA}</AccordianItemLabel>}
      >
        <RevitFamilyOutput />
      </AccordionItem>
      <AccordionItem
        title={<h3>{text.outputsBillOfMaterials} *</h3>}
        label={<AccordianItemLabel>{text.outputsCSV}</AccordianItemLabel>}
      >
        <BillOfMaterialsOutput />
      </AccordionItem>
      <AccordionItem
        title={<h3>{text.outputsInventorModel}</h3>}
        label={
          <AccordianItemLabelGroup>
            <AccordianItemLabel>{text.outputsIAM}</AccordianItemLabel>
            <AccordianItemLabel>{text.outputsIPT}</AccordianItemLabel>
          </AccordianItemLabelGroup>
        }
      >
        <InventorModelOutput />
      </AccordionItem>
      <AccordionItem
        title={<h3>{text.outputsInventorDrawing}</h3>}
        label={<AccordianItemLabel>{text.outputsIDW}</AccordianItemLabel>}
      >
        <InventorDrawingOutput />
      </AccordionItem>
      <AccordionItem
        title={<h3>{text.outputsShopDrawing}</h3>}
        label={<AccordianItemLabel>{text.outputsPDF}</AccordianItemLabel>}
      >
        <ShopDrawingOutput />
      </AccordionItem>
      <AccordionItem
        title={<h3>{text.outputsNeutralFormat}</h3>}
        label={<AccordianItemLabel>{text.outputsSAT}</AccordianItemLabel>}
      >
        <NeutralFormatOutput />
      </AccordionItem>
    </AccordionWrap>
  </TabFormContainer>
);

export default OutputsTab;
