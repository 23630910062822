import { Button, ProgressBar } from '@adsk/alloy-react';
import React, { useContext } from 'react';
import { PublishStatus } from '../../../lib/interfaces/templates';
import { PublishingComplete } from './PublishingComplete';
import { PublishingFailed } from './PublishingFailed';
import { ConfirmSelectionsButton, Title, Wrapper } from './Publishing.styles';
import { usePublishing } from './usePublishing';
import { ACCDocSelection } from '../../../Common/components/ACCDocSelection/ACCDocSelection';
import text from '../../../Common/global/text/text.json';
import { useACCDocSelection } from '../../../Common/components/ACCDocSelection/useACCDocSelection';
import DataContext from '../../context/DataStore/Data.context';
import {
  FlexContainer,
  LocationContainer,
  LocationInformation,
} from '../../../Common/global/styles/Common/Common.styles';
import ProductFolderBrowser from '../../../Common/components/ProductFolderBrowser/ProductFolderBrowser';

export const Publishing: React.FC = (): JSX.Element => {
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();
  const {
    products,
    productsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    isPublishDisabled,
    publishResponse,
    handleSelectFolder,
    handleNewTemplateClick,
    handleOpenSavedDraftsClick,
    handlePublishClick,
  } = usePublishing({ selectedAccount, selectedProject });
  const { currentDraft, currentDraftPublishStatus } = useContext(DataContext);

  if (currentDraftPublishStatus === PublishStatus.LOADING) {
    return (
      <Wrapper>
        <ProgressBar />
      </Wrapper>
    );
  }

  const renderPublishStatusComponents = (status: PublishStatus) => {
    switch (status) {
      case PublishStatus.IDLE:
        return (
          <LocationContainer>
            <Title>{text.newTemplateTitle}</Title>
            <LocationInformation>{text.newTemplateDescription}</LocationInformation>
            {accounts && (
              <>
                <FlexContainer alignItems="end">
                  <ACCDocSelection
                    accounts={accounts}
                    accountsLoading={accountsLoading}
                    projects={projects || []}
                    projectsLoading={projectsLoading}
                    selectedAccount={selectedAccount}
                    selectedProject={selectedProject}
                    projectsDisabled={projectsDisabled}
                    handleSelectAccount={handleSelectAccount}
                    handleSelectProject={handleSelectProject}
                  />
                  <ConfirmSelectionsButton
                    variant={Button.VARIANTS.PRIMARY}
                    disabled={isPublishDisabled}
                    onClick={handlePublishClick}
                  >
                    {text.buttonPublish}
                  </ConfirmSelectionsButton>
                </FlexContainer>
                <FlexContainer alignItems="end">
                  <ProductFolderBrowser
                    projectId={selectedProject?.id || ''}
                    rootFolders={rootFoldersTreeItems}
                    rootFoldersLoading={rootFoldersLoading}
                    rootFoldersError={rootFoldersError}
                    selectedFolderTreeElement={selectedFolderTreeItem}
                    products={products}
                    productsLoading={productsLoading}
                    productsError={productsError}
                    onFolderClick={handleSelectFolder}
                  />
                </FlexContainer>
              </>
            )}
          </LocationContainer>
        );
      case PublishStatus.LOADING:
        return (
          <Wrapper>
            <ProgressBar />
          </Wrapper>
        );
      case PublishStatus.COMPLETE:
        return (
          <Wrapper>
            <PublishingComplete
              currentDraft={currentDraft}
              handleNewTemplateClick={handleNewTemplateClick}
              handleOpenSavedDraftsClick={handleOpenSavedDraftsClick}
            />
          </Wrapper>
        );
      case PublishStatus.FAILURE:
        return (
          <Wrapper>
            <PublishingFailed
              draftName={currentDraft.name}
              draftAccount={currentDraft.account}
              draftProject={currentDraft.project}
              draftFolder={currentDraft.folder}
              publishResponse={publishResponse}
              handleNewTemplateClick={handleNewTemplateClick}
              handleOpenSavedDraftsClick={handleOpenSavedDraftsClick}
            />
          </Wrapper>
        );
      default:
        <></>;
    }
  };

  return <>{renderPublishStatusComponents(currentDraftPublishStatus)}</>;
};
