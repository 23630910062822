import styled from 'styled-components';

export const NumericFieldsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 340px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
