import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import DataContext from './context/DataStore/Data.context';
import { useStore as useDataStore } from './context/DataStore/dataStore';
import NavigationContext from './context/NavigationStore/Navigation.context';
import { useNavigationStore } from './context/NavigationStore/navigationStore';
import midTheme from '../Common/global/midTheme';
import Main from '../Common/components/Main/Main';
import { showDevTools, reloadBrowser } from '../lib/utils/tools';
import { ConfirmationModal } from '../Common/components/Modal/ConfirmationModal';
import { useModalStore } from '../Common/context/modalStore';
import ModalContext from '../Common/context/GlobalModal.context';
import { NotificationsProvider } from '@adsk/alloy-react';
import ErrorBoundary from '../Common/components/ErrorBoundary/ErrorBoundary';
import { EnvironmentInfo } from '../Common/components/EnvironmentInfo/EnvironmentInfo';
import TabProgressContext from './context/TabProgressStore/TabProgress.context';
import { useTabProgressStore } from './context/TabProgressStore/tabProgressStore';

const App: React.FC = (): JSX.Element => {
  const dataStore = useDataStore();
  const navigationStore = useNavigationStore();
  const modalStore = useModalStore();
  const { topLevelFolder, inventorProject, assembly, parameters, outputs } = dataStore.currentDraft;
  const tabProgressStore = useTabProgressStore({
    topLevelFolder,
    inventorProject,
    assembly,
    parameters,
    outputs,
  });

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F12') {
        showDevTools();
      }

      if (event.key === 'F5') {
        reloadBrowser();
      }
    });
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  return (
    <ErrorBoundary handleResetAppState={handleResetAppState}>
      <ThemeProvider theme={midTheme}>
        <ModalContext.Provider value={modalStore}>
          <ConfirmationModal />
          <NotificationsProvider>
            <NavigationContext.Provider value={navigationStore}>
              <DataContext.Provider value={dataStore}>
                <TabProgressContext.Provider value={tabProgressStore}>
                  <Header />
                  <Main>
                    <EnvironmentInfo hostname={window.location.host} />
                    <ScreenManager />
                  </Main>
                </TabProgressContext.Provider>
              </DataContext.Provider>
            </NavigationContext.Provider>
          </NotificationsProvider>
        </ModalContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
