import { Button, Icon } from '@adsk/alloy-react';
import React from 'react';
import text from '../../../../../Common/global/text/text.json';
import {
  DownloadIcon,
  InstructionsWrapper,
  TableSmallTitle,
  TableEditWrapper,
  TableRightButton,
  TableTextInput,
  Wrapper,
} from '../TablesTab.styles';

interface TableEditProps {
  currentTable: string;
  handleDeleteClick: () => void;
}

const TableEdit: React.FC<TableEditProps> = ({ currentTable, handleDeleteClick }): JSX.Element => (
  <Wrapper>
    <TableEditWrapper>
      <TableSmallTitle>{text.currentSpreadsheet}</TableSmallTitle>
      <TableTextInput type="text" placeholder="Not selected" defaultValue={currentTable} readOnly />
      <TableRightButton
        icon={Icon.TYPES.TRASH_CAN}
        size={Button.SIZES.MEDIUM}
        onClick={handleDeleteClick}
      >
        {text.buttonRemove}
      </TableRightButton>
    </TableEditWrapper>
    <InstructionsWrapper>
      <TableSmallTitle>{text.instructions}</TableSmallTitle>
      {/* TODO: Add table guide link when available */}
      <a href="#">
        {text.multiValueTableGuidelines}{' '}
        <DownloadIcon type={Icon.TYPES.CLOUD_DOWN_ARROW} fr={0} size={18} />
      </a>
    </InstructionsWrapper>
  </Wrapper>
);

export default TableEdit;
