import { useContext, useEffect, useState } from 'react';
import {
  InventorParameter,
  InventorProperties,
  IProperty,
} from '../../../../lib/interfaces/inventorProperties';
import {
  DraftTemplateIProperty,
  DraftTemplateInputParameter,
  DraftTemplateInputProperties,
} from '../../../../lib/interfaces/templates';
import {
  toDraftTemplateInputParameter,
  toDraftTemplateIProperty,
} from '../../../../lib/utils/drafts';
import DataContext from '../../../context/DataStore/Data.context';
import { fetchAllInventorData } from './InputsSelection/utils';
import { createFullPath } from '../../../../Common/global/path';
import {
  SetSelectedIProperties,
  SetSelectedParameters,
} from './InputsSelection/InputsSelection.types';
import { TemplateInputType } from '../../../../lib/interfaces/dynamicContent';

export type HandleSelectedParameterUpdateType = (
  input: DraftTemplateInputParameter,
  property: { [key: string]: any },
) => void;

export type HandleSelectedIPropertyUpdateType = (
  input: DraftTemplateIProperty,
  property: { [key: string]: any },
) => void;
import { useAsyncFetchDataWithArgs } from '../../../../Common/global/hooks/http/hooks';

export type HandleSelectedInputUpdateType = (
  input: DraftTemplateInputParameter | DraftTemplateIProperty,
  property: { [key: string]: any },
) => void;

export interface UseInputsTabState {
  isEditStep: boolean;
  inventorData: InventorProperties | null;
  selectedParameters: DraftTemplateInputParameter[];
  selectedIProperties: DraftTemplateIProperty[];
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedParameters: SetSelectedParameters;
  setSelectedIProperties: SetSelectedIProperties;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
  handleAddInputsButtonClick: () => void;
  loading: boolean;
  error: Error | null;
}

export const useInputsTab = (): UseInputsTabState => {
  const {
    currentDraft,
    addCurrentDraftParameters,
    addCurrentDraftIProperties,
    updateCurrentDraftParameter,
    updateCurrentDraftIProperty,
  } = useContext(DataContext);

  const [isEditStep, setIsEditStep] = useState(false);
  const [localSelectedParameters, setLocalSelectedParameters] = useState<
    DraftTemplateInputParameter[]
  >(currentDraft.parameters);
  const [localSelectedIProperties, setLocalSelectedIProperties] = useState<
    DraftTemplateIProperty[]
  >(currentDraft.iProperties);

  const [mergedInputsList, setMergedInputsList] = useState<DraftTemplateInputProperties>({
    parameters: [],
    iProperties: [],
  });
  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();

  const {
    data: inventorData,
    loading,
    error,
  } = useAsyncFetchDataWithArgs<InventorProperties>(fetchAllInventorData, fetchDependencyList);
  const inventorParameters: InventorParameter[] | undefined = inventorData?.parameters;
  const inventorIProperties: IProperty[] | undefined = inventorData?.iProperties;

  const setSelectedParameters = (names: { [key: string]: boolean }) => {
    const selectedParameters = mergedInputsList.parameters.filter((param) => param.name in names);
    setLocalSelectedParameters(selectedParameters);
  };

  const setSelectedIProperties = (ids: { [key: string]: boolean }) => {
    const selectedIProperties = mergedInputsList.iProperties.filter((prop) => prop.id in ids);
    setLocalSelectedIProperties(selectedIProperties);
  };

  const handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType = (input, property) => {
    if (input.type === TemplateInputType.IProperty) {
      updateCurrentDraftIProperty(input, property);
    } else {
      updateCurrentDraftParameter(input, property);
    }
  };

  const handleAddInputsButtonClick = () => {
    addCurrentDraftParameters(localSelectedParameters);
    addCurrentDraftIProperties(localSelectedIProperties);
    setIsEditStep(true);
  };

  // Initial Setup, getting assembly from currentDraft, which is needed to fetch Inventor Data
  useEffect(() => {
    const topLevelFolder = currentDraft.topLevelFolder;
    const assembly = currentDraft.assembly;
    if (topLevelFolder && assembly) {
      setFetchDependencyList([createFullPath(topLevelFolder, assembly)]);
    }
  }, [currentDraft.topLevelFolder, currentDraft.assembly]);

  // Initial Setup, update localSelectedParameters with currentDraft data
  useEffect(() => {
    setLocalSelectedParameters(currentDraft.parameters);
  }, [currentDraft.parameters]);

  // Initial Setup, update localSelectedParameters with currentDraft data
  useEffect(() => {
    setLocalSelectedIProperties(currentDraft.iProperties);
  }, [currentDraft.iProperties]);

  // Once Inventor Data has been fetched
  // merge it with selected parameters
  useEffect(() => {
    if (inventorParameters) {
      const mergedParameters = inventorParameters.map((param) => {
        const relatedInput = localSelectedParameters.find(
          (selectedParam) => selectedParam.name === param.name,
        );
        return relatedInput ? relatedInput : toDraftTemplateInputParameter(param);
      });

      setMergedInputsList((prev) => ({
        ...prev,
        parameters: mergedParameters,
      }));
    }
  }, [localSelectedParameters, inventorParameters]);

  // Once Inventor Data has been fetched
  // merge it with the selected iProperties
  useEffect(() => {
    if (inventorIProperties) {
      const mergedIProperties = inventorIProperties.map((iProperty) => {
        const relatedInput = localSelectedIProperties.find(
          (selectedIProperty) => selectedIProperty.id === iProperty.id,
        );
        return relatedInput ? relatedInput : toDraftTemplateIProperty(iProperty);
      });

      setMergedInputsList((prev) => ({
        ...prev,
        iProperties: mergedIProperties,
      }));
    }
  }, [localSelectedIProperties, inventorIProperties]);

  useEffect(() => {
    setIsEditStep(currentDraft.parameters.length + currentDraft.iProperties.length > 0);
  }, [currentDraft.iProperties.length, currentDraft.parameters.length]);

  return {
    isEditStep,
    inventorData,
    selectedParameters: localSelectedParameters,
    selectedIProperties: localSelectedIProperties,
    setIsEditStep,
    setSelectedParameters,
    setSelectedIProperties,
    handleSelectedInputDataStoreUpdate,
    handleAddInputsButtonClick,
    loading,
    error,
  };
};
