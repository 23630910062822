import { TextInput } from '@adsk/alloy-react';
import styled from 'styled-components';
import { FieldsetGrid } from '../../../Common/global/styles/Common/Common.styles';

export const BooleanFieldsetGrid = styled(FieldsetGrid)`
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto auto;

  p {
    margin: 0;
  }
`;

export const BooleanLabelInput = styled(TextInput)`
  width: 200px;
`;
