import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import text from '../../../Common/global/text/text.json';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  DraftTemplatePublishResult,
  MetaInfo,
  MetaInfoPath,
  PublishStatus,
} from '../../../lib/interfaces/templates';
import { publishDraftTemplate, saveDraft } from '../../../lib/utils/drafts';
import DataContext from '../../context/DataStore/Data.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { useAsyncFetchDataWithArgs } from '../../../Common/global/hooks/http/hooks';
import { getProjectFolders } from '../../../lib/utils/workspace';
import { ProjectFolder } from '../../../lib/interfaces/forge-api';
import { TreeItem } from '../../../Common/components/MIDTree/MIDTree.types';
import { DynamicContentProduct } from '../../../lib/interfaces/dynamicContent';
import { getAllProducts } from '../../../lib/utils/products';

interface UsePublishingProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
}
export interface UsePublishingState {
  products: DynamicContentProduct[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeItem: TreeItem | undefined;
  isPublishDisabled: boolean;
  handleSelectFolder: (item: TreeItem, path: MetaInfo[]) => void;
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
  handlePublishClick: () => Promise<void>;
  publishResponse: DraftTemplatePublishResult | undefined;
}

export const usePublishing = ({
  selectedAccount,
  selectedProject,
}: UsePublishingProps): UsePublishingState => {
  const {
    currentDraft,
    resetCurrentDraft,
    setCurrentDraftPublishLocation,
    setCurrentDraftPublishStatus,
  } = useContext(DataContext);
  const { setCurrentScreen } = useContext(NavigationContext);
  const { showNotification } = useNotifications();

  const [rootFoldersTreeItems, setRootFoldersDropdownItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeItem, setSelectedFolderDropdownItem] = useState<TreeItem | undefined>();
  const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [publishResponse, setPublishResponse] = useState<DraftTemplatePublishResult | undefined>();

  const [productsQueryArgs, setProductsQueryArgs] = useState<string[] | undefined>();
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useAsyncFetchDataWithArgs<DynamicContentProduct[]>(getAllProducts, productsQueryArgs);

  const [rootFoldersQueryArgs, setRootFoldersQueryArgs] = useState<string[] | undefined>();
  const {
    data: rootFolders,
    loading: rootFoldersLoading,
    error: rootFoldersError,
  } = useAsyncFetchDataWithArgs<ProjectFolder[]>(getProjectFolders, rootFoldersQueryArgs);

  const handleUpdateDataStore = useCallback(
    (folder: MetaInfoPath) => {
      if (selectedAccount && selectedProject) {
        setCurrentDraftPublishLocation(selectedAccount, selectedProject, folder);
      }
    },
    [selectedAccount, selectedProject, setCurrentDraftPublishLocation],
  );

  useEffect(() => {
    setRootFoldersQueryArgs(selectedProject?.id ? [selectedProject?.id] : undefined);
    setSelectedFolderDropdownItem(undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    const foldersTreeDropdown: TreeItem[] =
      rootFolders?.map((folder) => ({
        id: folder.urn,
        value: folder.urn,
        label: folder.title,
        isExpandable: true,
        children: [],
      })) || [];
    setRootFoldersDropdownItems(foldersTreeDropdown);
  }, [rootFolders]);

  useEffect(() => {
    setProductsQueryArgs(selectedProject?.id ? [selectedProject?.id] : undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    setIsPublishDisabled(!selectedFolderTreeItem);
  }, [selectedFolderTreeItem]);

  const handleSelectFolder = (selectedFolder: TreeItem, path: MetaInfo[]) => {
    if (selectedFolder.id) {
      setSelectedFolderDropdownItem({
        id: selectedFolder.id,
        value: selectedFolder.id,
        label: selectedFolder.label,
        isExpandable: selectedFolder.isExpandable,
      });

      const bimFolder: MetaInfoPath = {
        id: selectedFolder.id,
        name: selectedFolder.label.toString(),
        parentPath: path,
      };
      handleUpdateDataStore(bimFolder);
    }
  };

  const handleNewTemplateClick = () => {
    resetCurrentDraft();
    setCurrentDraftPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
  };

  const handleOpenSavedDraftsClick = () => {
    resetCurrentDraft();
    setCurrentDraftPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.DRAFT_TEMPLATES);
  };

  const handlePublishClick = async () => {
    setCurrentDraftPublishStatus(PublishStatus.LOADING);
    await publishTemplate();
  };

  const publishTemplate = async (): Promise<void> => {
    // We save the currentDraft regardless of the
    // outcome of publish
    saveDraft(currentDraft);
    const response: DraftTemplatePublishResult = await publishDraftTemplate(currentDraft);
    if (response.status.toLowerCase() === PublishStatus.COMPLETE) {
      setCurrentDraftPublishStatus(PublishStatus.COMPLETE);
      showNotification({
        message: text.notificationPublishDraftSuccess,
        status: NOTIFICATION_STATUSES.SUCCESS,
      });
    } else if (response.status.toLowerCase() === PublishStatus.FAILURE) {
      showNotification({
        message: text.notificationPublishDraftFailed,
        status: NOTIFICATION_STATUSES.ERROR,
      });
      setPublishResponse(response);
      setCurrentDraftPublishStatus(PublishStatus.FAILURE);
    }
  };

  return {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    isPublishDisabled,
    publishResponse,
    handleSelectFolder,
    handleNewTemplateClick,
    handleOpenSavedDraftsClick,
    handlePublishClick,
    products,
    productsLoading,
    productsError,
  };
};

export default usePublishing;
