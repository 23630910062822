import { ICON_TYPES, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import { TooltipIcon, TooltipWrapper } from '../../../../Common/global/styles/Common/Common.styles';
import {
  OutputHeader,
  OutputRow,
  OutputRowHeader,
  RadioButtonField,
  RadioButtonWrapper,
  RadioGroupWrapper,
} from './OutputsTab.styles';
import text from '../../../../Common/global/text/text.json';
import { OutputRepresentation } from './OutputsTab.types';

interface OutputRepresentationsProps {
  representations: OutputRepresentation[];
  selectedRepresentations: string[];
  handleRepresentationChange?: (value?: string) => void;
}

const OutputRepresentations: React.FC<OutputRepresentationsProps> = ({
  representations,
  selectedRepresentations,
  handleRepresentationChange,
}) => {
  const representaionControls = representations.map((representation, index) => (
    <RadioButtonWrapper key={index}>
      <RadioButtonField>
        {representation.name}
        {representation.nameInfoTooltip && (
          <TooltipWrapper>
            <Tooltip content={representation.nameInfoTooltip}>
              <TooltipIcon type={ICON_TYPES.QUESTION_CIRCLE_FILLED} size={17} fr={0} />
            </Tooltip>
          </TooltipWrapper>
        )}
      </RadioButtonField>
      <RadioButtonField>
        {representation.statusInfoTooltip ? (
          <Tooltip content={representation.statusInfoTooltip}>
            <representation.StatusControl {...representation.statusProps} />
          </Tooltip>
        ) : (
          <representation.StatusControl {...representation.statusProps} />
        )}
        {representation.status}
      </RadioButtonField>
    </RadioButtonWrapper>
  ));
  return (
    <>
      <OutputRow>
        <OutputHeader>
          <OutputRowHeader>{text.outputRepresentation}</OutputRowHeader>
        </OutputHeader>
        <OutputHeader>
          <OutputRowHeader>{text.outputStatus}</OutputRowHeader>
        </OutputHeader>
      </OutputRow>
      <OutputRow>
        <RadioGroupWrapper
          defaultValue={representations.length > 0 ? representations[0].name : ''}
          value={selectedRepresentations.length > 0 ? selectedRepresentations[0] : undefined}
          onChange={handleRepresentationChange}
        >
          {representaionControls}
        </RadioGroupWrapper>
      </OutputRow>
    </>
  );
};

export default OutputRepresentations;
