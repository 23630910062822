import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
} from '../interfaces/inventorAutomation';
import { HostApi } from '../interfaces/cefSharp';
import { InventorProperties } from '../interfaces/inventorProperties';
import logger from '../../Common/global/logger';

declare let hostApi: HostApi;

export const getPartOrAssemblyProperties = async (path: string): Promise<InventorProperties> => {
  const result = await hostApi.getPartOrAssemblyProperties(path);
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const getModelStates = async (documentPath: string): Promise<string[]> => {
  const modelStatesResult = await hostApi.getModelStates(documentPath);
  if (modelStatesResult.value === null) {
    throw new Error(`${modelStatesResult.errorMessage}`);
  }
  return modelStatesResult.value;
};

export const generateOutputs = async (
  topFolderPath: string,
  documentPath: string,
  inputs: InventorInput[],
  requestedOutputs: InventorOutput[],
): Promise<GenerateOutputsResult> => {
  const inputsJson = JSON.stringify(inputs);
  const requestedOutputsJson = JSON.stringify(requestedOutputs);
  const generateOutputsResult = await hostApi.generateOutputs(
    topFolderPath,
    documentPath,
    inputsJson,
    requestedOutputsJson,
  );

  return generateOutputsResult;
};

export const openDraftTemplateDocument = async (
  documentPath: string,
  inputs: InventorInput[],
): Promise<boolean> => {
  const openStatus = await hostApi.isDocumentOpenInTheEditor(documentPath);
  if (!openStatus) {
    return false;
  }

  const inputsJson = JSON.stringify(inputs);

  const result = await hostApi.openDraftTemplateDocument(documentPath, inputsJson);
  if (!result.value) {
    logger.error(`${result.errorMessage}`);
    return false;
  }
  return true;
};

export const isDocumentOpenInTheEditor = async (documentPath: string): Promise<boolean> => {
  const result = await hostApi.isDocumentOpenInTheEditor(documentPath);
  if (!result.value) {
    if (result.errorMessage) {
      logger.error(`${result.errorMessage}`);
    }
    return false;
  }
  return true;
};
