import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import React, { useContext, useState } from 'react';
import ModalContext from '../../Common/context/GlobalModal.context';
import { initialModalState } from '../../Common/context/modalStore';
import { useBase64Thumbnail } from '../../Common/global/hooks/productMetadata/hooks';
import logger from '../../Common/global/logger';
import text from '../../Common/global/text/text.json';
import { DynamicContentProduct } from '../../lib/interfaces/dynamicContent';
import { MetaInfo } from '../../lib/interfaces/templates';
import { getDownloadUrl } from '../../lib/utils/cloudStorage';
import { productTemplateToDraftTemplate, saveDraft } from '../../lib/utils/drafts';
import {
  downloadFileFromUrl,
  extractZipFileToFolder,
  selectFolder,
} from '../../lib/utils/filesystem';

interface UseTemplateManagementScreenProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  selectedProduct: DynamicContentProduct | undefined;
  setSelectedProduct: React.Dispatch<React.SetStateAction<DynamicContentProduct | undefined>>;
  handleDeleteProduct: (selectedProduct: DynamicContentProduct) => void;
}

export interface UseTemplateManagementScreenState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  isdownloadInProgress: boolean;
  handleDeleteProductTemplateClick: () => void;
  handleDownloadDraftClick: () => void;
}

export const useTemplateManagementScreen = ({
  selectedAccount,
  selectedProject,
  selectedProduct,
  setSelectedProduct,
  handleDeleteProduct,
}: UseTemplateManagementScreenProps): UseTemplateManagementScreenState => {
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useNotifications();

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    selectedProduct?.tenancyId,
    selectedProduct?.thumbnail,
  );
  const [isdownloadInProgress, setIsDownloadInProgress] = useState(false);

  const handleDownloadDraftClick = async () => {
    try {
      setIsDownloadInProgress(true);
      if (selectedProduct) {
        const productUrl = await getDownloadUrl(
          selectedProduct?.tenancyId,
          selectedProduct?.dataSetLocation,
        );
        const downloadLocation = await downloadFileFromUrl(productUrl);
        const draftLocation = await uncompressAndSaveDraft(downloadLocation);
        if (draftLocation) {
          await generateDraftFromTemplate(selectedProduct, draftLocation);
        }
        showNotification({
          message: text.notificationDownloadDraftSuccess,
          status: NOTIFICATION_STATUSES.SUCCESS,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error(error.message);
        showNotification({
          message: text.failedToDownloadFile,
          status: NOTIFICATION_STATUSES.ERROR,
        });
        return Promise.reject(error.message);
      }
    } finally {
      setIsDownloadInProgress(false);
    }
  };

  const uncompressAndSaveDraft = async (filePath: string) => {
    try {
      const folder = await selectFolder();
      if (folder) {
        setIsDownloadInProgress(true);
        const selectedDownloadLocation = await extractZipFileToFolder(filePath, folder);
        return selectedDownloadLocation;
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(text.failedToUnzipFile);
      }
    }
  };

  const generateDraftFromTemplate = async (
    product: DynamicContentProduct,
    selectedDownloadLocation: string,
  ) => {
    try {
      const newDraftFromProduct = await productTemplateToDraftTemplate(
        selectedAccount,
        selectedProject,
        selectedDownloadLocation,
        product,
      );
      await saveDraft(newDraftFromProduct);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error(error as any);
    }
  };

  const _handleDeleteProductTemplateConfirmation = async () => {
    if (selectedProduct) {
      try {
        await handleDeleteProduct(selectedProduct);
        showNotification({
          message: text.deleteTemplateSuccessMessage,
          status: NOTIFICATION_STATUSES.SUCCESS,
        });
        setSelectedProduct(undefined);
      } catch (error: unknown) {
        showNotification({
          message: text.deleteTemplateFailMessage,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }
  };

  const handleDeleteProductTemplateClick = () => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      message: text.deleteTemplateWarningConfirmQuestion,
      onConfirmCallback: _handleDeleteProductTemplateConfirmation,
    });
  };

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    isdownloadInProgress,
    handleDeleteProductTemplateClick,
    handleDownloadDraftClick,
  };
};

export default useTemplateManagementScreen;
