import { TextInput } from '@adsk/alloy-react';
import styled from 'styled-components';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';

export const SourceContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const SourceContentTitle = styled.h2`
  ${({ theme }) => theme.typography.heading4}
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
  line-height: ${({ theme }) => `${theme.vars.marginBase * 2.5}px`};
`;

export const SourceContentRightButton = styled(RightButton)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
  margin-bottom: 4px; // To better alignment with input
  vertical-align: bottom;
`;

export const SourceContentFormWrapper = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
  background-color: ${({ theme }) => theme.colors.charcoal100};
`;

export const SourceContentForm = styled.form`
  display: inline-block;
`;

export const SourceContentTextInput = styled(TextInput)`
  width: 300px;
  display: inline-block;
  vertical-align: top;
`;

export const SourceContentPreviewImage = styled.img`
  width: 100%;
  height: auto;
`;

export const SourceContentPreviewWrapper = styled.div`
  display: flex;
`;

export const SourceContentPreviewImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 30%;
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const SourceContentSummaryTableWrapper = styled.div`
  flex: 2 0 auto;
  max-width: 70%;

  table {
    word-break: break-word;
  }

  td:first-child {
    width: 20%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.desktop}) {
    td:first-child {
      width: 30%;
    }
  }
`;
