import Blockly, { BlockSvg } from 'blockly';
import { BlocklyState } from '../BlocklyModule.types';
import { blocklyDropdown, blocklyFunctionsDropdown, customBlocklyMutators } from '../constants';

/*
 * Blockly mutators are used to tell blockly how to handle "Dynamic" blocks.
 *
 * Mutators come in handy when you want to serialize Blockly's Workspace
 *  for example when you call: Blockly.serialization.workspaces.save(blocklyWorkspace),
 *
 * In this case, we use mutators to save the state of the input dropdowns, specifically,
 * it allows us to save and load the selected input value.
 */

export const initializeBlocklyMutators = (): void => {
  if (!Blockly.Extensions.isRegistered(customBlocklyMutators.INPUT_DROPDOWN_MUTATOR)) {
    Blockly.Extensions.registerMutator(customBlocklyMutators.INPUT_DROPDOWN_MUTATOR, {
      saveExtraState(this: BlockSvg) {
        const inputsDropdown = this.getInput(blocklyDropdown);
        const functionsDropdown = this.getInput(blocklyFunctionsDropdown);

        if (inputsDropdown && functionsDropdown) {
          return {
            inputsDropdown: inputsDropdown.fieldRow[1].getValue(),
            functionsDropdown: functionsDropdown.fieldRow[1].getValue(),
          };
        }
      },
      loadExtraState(this: BlockSvg, state: BlocklyState) {
        const inputsDropdown = this.getInput(blocklyDropdown);
        const functionsDropdown = this.getInput(blocklyFunctionsDropdown);
        if (inputsDropdown && functionsDropdown) {
          inputsDropdown.fieldRow[1].setValue(state.inputsDropdown);
          functionsDropdown.fieldRow[1].setValue(state.functionsDropdown);
        }
      },
    });
  }
};
