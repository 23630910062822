import { Button, ICON_TYPES } from '@adsk/alloy-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';
import {
  LeftSection,
  RightSection,
  Subtitle,
} from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';

const Initial: React.FC<{
  handleNewTemplateClick: () => void;
  handleManageTemplatesClick: () => void;
  handleMidWebPortalClick: () => void;
}> = ({
  handleNewTemplateClick,
  handleManageTemplatesClick,
  handleMidWebPortalClick,
}): JSX.Element => {
  const { enableMidWebPortal, enableTemplateManagement } = useFlags();

  return (
    <>
      <LeftSection>
        <Subtitle>{text.subHeaderTitleInitial}</Subtitle>
      </LeftSection>
      <RightSection>
        {enableMidWebPortal && (
          <RightButton
            onClick={handleMidWebPortalClick}
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.PRIMARY}
          >
            {text.buttonGoToMidWebPortal}
          </RightButton>
        )}
        {enableTemplateManagement && (
          <RightButton
            icon={ICON_TYPES.DOCUMENT_TWO}
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.PRIMARY}
            onClick={handleManageTemplatesClick}
          >
            {text.manageTemplates}
          </RightButton>
        )}
        <RightButton
          icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
          size={Button.SIZES.SMALL}
          variant={Button.VARIANTS.PRIMARY}
          onClick={handleNewTemplateClick}
        >
          {text.buttonNewProductDefinition}
        </RightButton>
      </RightSection>
    </>
  );
};

export default Initial;
