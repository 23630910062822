import { Column } from '@adsk/alloy-react-table';
import { InventorParameter, IProperty } from '../../../../../lib/interfaces/inventorProperties';
import text from '../../../../../Common/global/text/text.json';

export const parameterTableColumns: Column<Record<string, InventorParameter>>[] = [
  {
    id: text.inputsTableColumnNameId,
    accessor: text.inputsTableColumnNameId,
    renderHeader: () => text.inputsTableColumnNameHeader,
  },
  {
    id: text.inputsTableColumnParameterTypeId,
    accessor: text.inputsTableColumnParameterTypeId,
    renderHeader: () => text.inputsTableColumnParameterTypeHeader,
  },
  {
    id: text.inputsTableColumnUnitId,
    accessor: text.inputsTableColumnUnitId,
    renderHeader: () => text.inputsTableColumnUnitHeader,
  },
  {
    id: text.inputsTableColumnValueId,
    accessor: text.inputsTableColumnValueId,
    renderHeader: () => text.inputsTableColumnDefaultValueHeader,
  },
];

export const iPropertiesTableColumns: Column<Record<string, IProperty>>[] = [
  {
    id: text.inputsTableColumnDisplayNameId,
    accessor: text.inputsTableColumnDisplayNameId,
    renderHeader: () => text.inputsTableColumnNameHeader,
  },
  {
    id: text.inputsTableColumnCategoryId,
    accessor: text.inputsTableColumnCategoryId,
    renderHeader: () => text.inputsTableColumnCategoryHeader,
  },
  {
    id: text.inputsTableColumnValueId,
    accessor: text.inputsTableColumnValueId,
    renderHeader: () => text.inputsTableColumnValueHeader,
  },
];
