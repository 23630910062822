import { useContext, useEffect, useState } from 'react';
import { TextEditor } from 'react-data-grid';
import { read, utils, WorkBook } from 'xlsx';
import DataContext from '../../../context/DataStore/Data.context';
import { Column, DataGridRows, DataSet, Row } from './Tables.types';
import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react-notification';
import text from '../../../../Common/global/text/text.json';

interface UseTablesState {
  tableFile: string;
  sheets: string[];
  dataGridRows: DataGridRows;
  columns: Column[];
  currentSheet: string;
  handleNextClick: () => void;
  handleDeleteClick: () => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectSheet: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setDataGridRows: React.Dispatch<React.SetStateAction<DataGridRows>>;
}

const useTables = (): UseTablesState => {
  const { currentDraft, setCurrentDraftTable, deleteCurrentDraftTable } = useContext(DataContext);
  const { showNotification } = useNotifications();

  const [tableFile, setTableFile] = useState(currentDraft.table?.name || '');
  const [tableData, setTableData] = useState<WorkBook>(
    currentDraft.table?.data || ({} as WorkBook),
  );
  const [workbook, setWorkbook] = useState<DataSet>(currentDraft.table?.data.Sheets || {});
  const [sheets, setSheets] = useState<string[]>(currentDraft.table?.data.SheetNames || []);
  const [dataGridRows, setDataGridRows] = useState<DataGridRows>({ header: [], data: [] });
  const [columns, setColumns] = useState<Column[]>([]);
  const [currentSheet, setCurrentSheet] = useState<string>('');

  const parseAB = (ab: ArrayBuffer): WorkBook | Error => {
    try {
      return read(ab, {
        type: 'array',
      });
    } catch (e) {
      return e instanceof Error ? e : new Error(e as any);
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const data = await file.arrayBuffer();
      const workbook = parseAB(data);
      if (workbook instanceof Error) {
        showNotification({
          message: text.tableFileError,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        setTableFile(file.name);
        setTableData(workbook);
        setWorkbook(workbook.Sheets);
        setSheets(workbook.SheetNames);
      }
    }
  };

  const handleNextClick = () => {
    setCurrentDraftTable(tableFile, tableData);
    // Loading first sheet
    loadSheet(sheets[0]);
  };

  const getRowsAndCols = (sheetName: string): { header: Row; rows: Row[]; columns: Column[] } => {
    const rows: Row[] = utils.sheet_to_json(tableData.Sheets[sheetName], { header: 1, raw: true });
    const [headerRow, ...restOfRows] = rows;
    let columns: Column[] = [];

    columns = headerRow.map((header: string, index: number) => ({
      key: index.toString(),
      name: header,
      editor: TextEditor,
      resizable: true,
    }));

    setCurrentSheet(sheetName);
    return { header: headerRow, rows: restOfRows, columns };
  };

  const loadSheet = (sheetName: string) => {
    const { rows: newRows, columns: newColumns, header } = getRowsAndCols(sheetName);
    setDataGridRows({ header, data: newRows });
    setColumns(newColumns);
    setCurrentSheet(sheetName);
  };

  const handleSelectSheet = (event: React.MouseEvent<HTMLButtonElement>) => {
    const sheetName = event.currentTarget.name;
    loadSheet(sheetName);
  };

  useEffect(() => {
    // Save state of previous Table
    workbook[currentSheet] = utils.json_to_sheet([dataGridRows.header, ...dataGridRows.data], {
      header: columns.map((col: Column) => col.key),
      skipHeader: true,
    });
  }, [workbook, currentSheet, columns, dataGridRows.data, dataGridRows.header]);

  const handleDeleteClick = () => {
    deleteCurrentDraftTable();
    setTableFile('');
    setDataGridRows({ header: [], data: [] });
    setColumns([]);
    setCurrentSheet('');
  };

  return {
    tableFile,
    sheets,
    dataGridRows,
    columns,
    currentSheet,
    handleNextClick,
    handleDeleteClick,
    handleFileSelect,
    handleSelectSheet,
    setDataGridRows,
  };
};

export default useTables;
