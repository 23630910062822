import { useState, useEffect } from 'react';
import { TemplateInputType } from '../../../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
} from '../../../../../lib/interfaces/templates';

export type SelectedIdsMap = { [key: string]: boolean };

export const useTransformToTableIds = (
  items: DraftTemplateInputParameter[] | DraftTemplateIProperty[],
): SelectedIdsMap => {
  const [selectedIds, setSelectedIds] = useState<SelectedIdsMap>({});

  useEffect(() => {
    const ids: SelectedIdsMap = {};
    items.forEach((item) => {
      if (item.type !== TemplateInputType.IProperty) {
        ids[item.name] = true;
      } else {
        ids[item.id] = true;
      }
    });
    setSelectedIds(ids);
  }, [items]);

  return selectedIds;
};
