import React from 'react';
import text from '../../../../../Common/global/text/text.json';
import SummaryTable, {
  SummaryTableRow,
} from '../../../../../Common/components/SummaryTable/SummaryTable';
import {
  SourceContentPreviewImage,
  SourceContentPreviewImageWrapper,
  SourceContentPreviewWrapper,
  SourceContentSummaryTableWrapper,
  SourceContentTitle,
} from '../SourceContentTab.styles';
import {
  TooltipIcon,
  TooltipWrapper,
} from '../../../../../Common/global/styles/Common/Common.styles';
import { Icon, ILLUSTRATION_TYPES, Tooltip } from '@adsk/alloy-react';
import MIDEmptyState from '../../../../../Common/components/EmptyState/MIDEmptyState';

interface PreviewProps {
  topLevelFolder: string;
  iPJ: string;
  topLevelAssembly: string;
  path: string;
  thumbnailInBase64: string | undefined;
}

const Preview: React.FC<PreviewProps> = ({
  topLevelFolder,
  iPJ,
  topLevelAssembly,
  path,
  thumbnailInBase64,
}): JSX.Element => {
  const previewTableData: SummaryTableRow[] = [
    {
      title: text.sourceContentTopLevelFolderLabel,
      value: topLevelFolder,
    },
    {
      title: text.sourceContentIPJLabel,
      value: iPJ || text.NotSelectedLabel,
    },
    {
      title: text.sourceContentTopLevelAssemblyLabel,
      value: topLevelAssembly,
    },
    {
      title: text.sourceContentPath,
      value: path,
    },
  ];

  return (
    <SourceContentPreviewWrapper>
      <SourceContentSummaryTableWrapper>
        <SourceContentTitle>{text.sourceContentTitle}</SourceContentTitle>
        <SummaryTable data={previewTableData} />
      </SourceContentSummaryTableWrapper>
      <SourceContentPreviewImageWrapper>
        <SourceContentTitle>
          {text.sourceContentPreviewThumbnail}
          <TooltipWrapper>
            <Tooltip content={text.sourceContentPreviewTooltip}>
              <TooltipIcon type={Icon.TYPES.QUESTION_CIRCLE_FILLED} fr={0} />
            </Tooltip>
          </TooltipWrapper>
        </SourceContentTitle>
        {thumbnailInBase64 ? (
          <SourceContentPreviewImage
            src={`data:image/jpeg;base64,${thumbnailInBase64}`}
            alt={text.previewImageAltText}
          />
        ) : (
          <MIDEmptyState
            title={text.notificationThumbnailFailed}
            illustrationType={ILLUSTRATION_TYPES.NO_RESULTS}
          />
        )}
      </SourceContentPreviewImageWrapper>
    </SourceContentPreviewWrapper>
  );
};

export default Preview;
