import React from 'react';
import BlocklyModule from '../../BlocklyModule/BlocklyModule';
import { TabContainer } from '../../../../Common/global/styles/Common/Common.styles';

const RulesTab: React.FC = (): JSX.Element => (
  <TabContainer>
    <BlocklyModule />
  </TabContainer>
);

export default RulesTab;
