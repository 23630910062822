import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  display: flex;
  ${({ theme }) => theme.typography.labelSmall}
  margin: 0 ${({ theme }) => `${theme.vars.marginBase * 2}px`} ${({ theme }) =>
    `${theme.vars.marginBase}px`} 0;
`;

export const CheckboxLabelText = styled.span`
  padding: 0 0 ${({ theme }) => `${theme.vars.paddingBase / 2}px`}
    ${({ theme }) => `${theme.vars.paddingBase / 2}px`};
`;
