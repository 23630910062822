export type CallInputDataStoreUpdateHandlerArgs = (
  property: { [key: string]: any },
  shouldDebounceUpdate?: boolean,
) => void;

export enum TabProgress {
  COMPLETED = 'COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  EMPTY = 'EMPTY',
}
