import React from 'react';
import { OutputContainer } from './OutputsTab.styles';
import OutputRepresentations from './OutputRepresentations';
import { inventorDrawingOutputRepresentations } from './constants';

const InventorDrawingOutput: React.FC = (): JSX.Element => (
  <OutputContainer>
    <OutputRepresentations
      representations={inventorDrawingOutputRepresentations}
      selectedRepresentations={[]}
    />
  </OutputContainer>
);
export default InventorDrawingOutput;
