import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyInputParameterDropdownValues } from '../BlocklyModule.types';
import {
  blocklyConnectingBlock,
  blocklyDropdown,
  blocklyFunctionsDropdown,
  universalInputBlock,
  universalOutputBlock,
} from '../constants';
import { BlockSvg } from 'blockly';

/*
 * This file is used to tell Blockly how the custom blocks generate code.
 */

javascriptGenerator[universalInputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getInput(blocklyDropdown)?.fieldRow[1].getValue();
  const dropdownAction = this.getInput(blocklyFunctionsDropdown)?.fieldRow[1].getValue();
  const valueConnectingBlock = javascriptGenerator.valueToCode(
    this,
    blocklyConnectingBlock,
    javascriptGenerator.ORDER_ATOMIC,
  );

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);
  return `parameters['${dropdownValue.name}'].${dropdownAction} = ${valueConnectingBlock};`;
};

javascriptGenerator[universalOutputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getInput(blocklyDropdown)?.fieldRow[1].getValue();
  const dropdownAction = this.getInput(blocklyFunctionsDropdown)?.fieldRow[1].getValue();

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);

  const code = `parameters['${dropdownValue.name}'].${dropdownAction}`;
  return [code, javascriptGenerator.ORDER_NONE];
};
