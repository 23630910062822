import React, { useContext, useState } from 'react';
import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react-notification';
import { Button } from '@adsk/alloy-react';
import { debounce, isUndefined } from 'lodash';
import {
  BlocklyPlaceholder,
  BlocklyWrapper,
  PreviewPlaceholder,
  ControlsWrapper,
} from './BlocklyModule.styles';
import useBlocklyModule from './useBlocklyModule';
import DataContext from '../../context/DataStore/Data.context';
import ProductCustomizationForm from '../../../Common/components/ProductCustomization/ProductCustomizationForm';
import codeRunner from '../../../Common/components/JSInterpreter/codeRunner';
import text from '../../../Common/global/text/text.json';
import logger from '../../../Common/global/logger';
import useGenerateBlocklyState from './useGenerateBlocklyState';
import { DraftTemplateInputParameter } from '../../../lib/interfaces/templates';
import { DynamicContentInput } from '../../../lib/interfaces/dynamicContent';

const DEBOUNCED_INPUT_DELAY_IN_MS = 300;

const BlocklyModule: React.FC = (): JSX.Element => {
  const { currentDraft } = useContext(DataContext);
  const inputState = useGenerateBlocklyState();
  const { ref, getCode, blocklyWorkspace } = useBlocklyModule({
    initialState: currentDraft.codeBlocksWorkspace || inputState,
    shouldCleanupBlocks: isUndefined(currentDraft.codeBlocksWorkspace),
  });
  const [inputs, setInputs] = useState(currentDraft.parameters);
  const { showNotification } = useNotifications();

  const runCode = (formInputs: DraftTemplateInputParameter[]) => {
    if (!blocklyWorkspace) {
      logger.warn(text.blocklyWorkspaceNotInitialized);
      showNotification({
        message: text.blocklyWorkspaceNotInitialized,
        status: NOTIFICATION_STATUSES.WARNING,
      });
      return;
    }
    const blocklyCode = getCode(true);
    const { hasError, errorMessage, result } = codeRunner(
      blocklyCode,
      formInputs,
      blocklyWorkspace,
    );

    if (hasError) {
      showNotification({
        message: errorMessage,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    } else {
      setInputs(result);
    }
  };

  const handleCodeRunnerClick = () => runCode(inputs);

  const handleDebouncedInputUpdate = debounce((payload: DynamicContentInput) => {
    const updatedInputs = inputs.reduce(
      (
        updatedFormData: DraftTemplateInputParameter[],
        inputRow: DraftTemplateInputParameter,
      ): DraftTemplateInputParameter[] => {
        if (inputRow.name === payload.name) {
          return [
            ...updatedFormData,
            { ...inputRow, value: payload.value } as DraftTemplateInputParameter,
          ];
        }

        return [...updatedFormData, inputRow];
      },
      [],
    );

    runCode(updatedInputs);
  }, DEBOUNCED_INPUT_DELAY_IN_MS);

  return (
    <BlocklyWrapper>
      <BlocklyPlaceholder ref={ref} />
      <PreviewPlaceholder>
        <ControlsWrapper>
          <Button onClick={handleCodeRunnerClick}>{text.blocklyRunButton}</Button>
        </ControlsWrapper>
        <ProductCustomizationForm inputs={inputs} handleInputUpdate={handleDebouncedInputUpdate} />
      </PreviewPlaceholder>
    </BlocklyWrapper>
  );
};

export default BlocklyModule;
