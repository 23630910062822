import React, { useContext } from 'react';
import { TabContainer } from '../../../../Common/global/styles/Common/Common.styles';
import DataContext from '../../../context/DataStore/Data.context';
import UploadBox from './UploadTableStep/UploadBox';
import useTables from './useTables';
import TableView from './ViewTableStep/TableView';

const TablesTab: React.FC = (): JSX.Element => {
  const {
    tableFile,
    sheets,
    dataGridRows,
    columns,
    currentSheet,
    handleNextClick,
    handleDeleteClick,
    handleFileSelect,
    handleSelectSheet,
    setDataGridRows,
  } = useTables();

  const { currentDraft } = useContext(DataContext);

  return (
    <TabContainer>
      {!currentDraft.table ? (
        <UploadBox
          tableFile={tableFile}
          handleDeleteClick={handleDeleteClick}
          handleNextClick={handleNextClick}
          handleFileSelect={handleFileSelect}
        />
      ) : (
        <TableView
          sheets={sheets}
          dataGridRows={dataGridRows}
          columns={columns}
          currentSheet={currentSheet}
          selectedTable={tableFile}
          handleSelectSheet={handleSelectSheet}
          handleDeleteClick={handleDeleteClick}
          setDataGridRows={setDataGridRows}
        />
      )}
    </TabContainer>
  );
};

export default TablesTab;
