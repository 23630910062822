import { Button, ICON_TYPES } from '@adsk/alloy-react';
import React from 'react';
import {
  LeftSection,
  RightSection,
  Subtitle,
} from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';

const ManageTemplates: React.FC<{
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}> = ({ handleNewTemplateClick, handleOpenSavedDraftsClick }): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.manageTemplates}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton size={Button.SIZES.SMALL} onClick={handleOpenSavedDraftsClick}>
        {text.buttonSavedDrafts}
      </RightButton>
      <RightButton
        icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
        size={Button.SIZES.SMALL}
        onClick={handleNewTemplateClick}
      >
        {text.buttonNewProductDefinition}
      </RightButton>
    </RightSection>
  </>
);

export default ManageTemplates;
