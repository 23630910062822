import { useEffect, useState } from 'react';
import text from '../../../../../Common/global/text/text.json';
export interface UseInputsHeaderState {
  isParameter: boolean;
  buttonGroupValue: string;
  handleOnParameterOriPropertyButtonChange: (value: string) => void;
  currentFilter: string;
  setCurrentFilter: React.Dispatch<React.SetStateAction<string>>;
  filterItems: string[];
}

export const useInputsHeader = (): UseInputsHeaderState => {
  const [isParameter, setIsParameter] = useState(true);
  const [buttonGroupValue, setButtonGroupValue] = useState(text.inputsParameters);
  const [currentFilter, setCurrentFilter] = useState(text.inputsFilterOptionUser);
  const [filterItems, setFilterItems] = useState<string[]>([]);
  // Set filter items based on isParameter
  useEffect(() => {
    const items = isParameter
      ? [
          text.inputsFilterOptionAll,
          text.inputsFilterOptionModel,
          text.inputsFilterOptionUser,
          text.inputsFilterOptionKey,
          text.inputsFilterOptionSelected,
        ]
      : [
          text.inputsFilterOptionAll,
          text.inputsFilterOptionWithValue,
          text.inputsFilterOptionSelected,
        ];
    const defaultFilter = isParameter ? text.inputsFilterOptionUser : text.inputsFilterOptionAll;

    setFilterItems(items);
    setCurrentFilter(defaultFilter);
    setButtonGroupValue(isParameter ? text.inputsParameters : text.inputsProperties);
  }, [isParameter]);

  const handleOnParameterOriPropertyButtonChange = (value: string) =>
    setIsParameter(value === text.inputsParameters);

  return {
    isParameter,
    buttonGroupValue,
    currentFilter,
    setCurrentFilter,
    filterItems,
    handleOnParameterOriPropertyButtonChange,
  };
};
