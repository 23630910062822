import React from 'react';
import { OutputContainer } from './OutputsTab.styles';
import OutputRepresentations from './OutputRepresentations';
import { neutralFormatOutputRepresentations } from './constants';

const NeutralFormatOutput: React.FC = (): JSX.Element => (
  <OutputContainer>
    <OutputRepresentations
      representations={neutralFormatOutputRepresentations}
      selectedRepresentations={[]}
    />
  </OutputContainer>
);

export default NeutralFormatOutput;
