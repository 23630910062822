import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import { cloneDeep, debounce, isUndefined } from 'lodash';
import { useMemo, useEffect, useState, useContext } from 'react';
import logger from '../../../../../Common/global/logger';
import { TemplateInputType } from '../../../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateInputParameter,
  DraftTemplateInputProperties,
  DraftTemplateIProperty,
} from '../../../../../lib/interfaces/templates';
import { CallInputDataStoreUpdateHandlerArgs } from '../../../../types';
import { HandleSelectedInputUpdateType } from '../useInputsTab';
import text from '../../../../../Common/global/text/text.json';
import DataContext from '../../../../context/DataStore/Data.context';

interface UseEditInputsState {
  selectedInput: DraftTemplateInputProperties;
  selectedParameterInfo: DraftTemplateInputParameter | null;
  selectediPropertyInfo: DraftTemplateIProperty | null;
  handleChangeSelectedInputs: () => void;
  handleParameterSelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleIPropertySelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  handleParameterMoveUp: (
    parameter: DraftTemplateInputParameter,
    parameterIndex: number,
  ) => (event: React.MouseEvent<SVGSVGElement>) => void;
  handleParameterMoveDown: (
    parameter: DraftTemplateInputParameter,
    parameterIndex: number,
  ) => (event: React.MouseEvent<SVGSVGElement>) => void;
  isCodeblocksWorkspaceUndefined: boolean;
  setCodeblocksWorkspaceToUndefined: () => void;
}
interface useEditInputsArgs {
  selectedParameters: DraftTemplateInputParameter[];
  selectedIProperties: DraftTemplateIProperty[];
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

export const useEditInputs = ({
  selectedParameters,
  selectedIProperties,
  setIsEditStep,
  handleSelectedInputDataStoreUpdate,
}: useEditInputsArgs): UseEditInputsState => {
  const { showNotification } = useNotifications();
  const { currentDraft, setCurrentDraftCodeBlocksWorkspace, addCurrentDraftParameters } =
    useContext(DataContext);

  const selectedInput: DraftTemplateInputProperties = {
    parameters: selectedParameters,
    iProperties: selectedIProperties,
  };
  const [selectedParameterInfo, setSelectedParameterInfo] =
    useState<DraftTemplateInputParameter | null>(selectedParameters[0] ?? null);
  const [selectediPropertyInfo, setSelectediPropertyInfo] = useState<DraftTemplateIProperty | null>(
    selectedIProperties[0] ?? null,
  );

  const _callDebounceInputValueUpdate = useMemo(
    () =>
      debounce(
        (
          input: DraftTemplateInputParameter | DraftTemplateIProperty,
          property: {
            [key: string]: any;
          },
        ) => {
          // Update dataStore
          handleSelectedInputDataStoreUpdate(input, property);

          // To update the selected input in the UI
          if (input.type === TemplateInputType.IProperty) {
            setSelectediPropertyInfo({ ...input, ...property });
          } else {
            setSelectedParameterInfo({ ...input, ...property });
          }
        },
        300,
      ),
    [handleSelectedInputDataStoreUpdate],
  );

  // We cancel any pending debounces
  // when the component unmounts to prevent
  // stale updates
  useEffect(
    () => () => {
      _callDebounceInputValueUpdate.cancel();
    },
    [_callDebounceInputValueUpdate],
  );

  const handleChangeSelectedInputs = () => {
    setIsEditStep(false);
  };

  const handleParameterSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedParameterName = event.currentTarget.name;
    const parameter = selectedParameters.find(
      (parameter) => parameter.name === selectedParameterName,
    );
    if (parameter) {
      setSelectedParameterInfo(parameter);
      setSelectediPropertyInfo(null);
    }
  };

  const handleIPropertySelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedIPropertyID = event.currentTarget.id;
    const iProperty = selectedIProperties.find((iProperty) => iProperty.id === selectedIPropertyID);
    if (iProperty) {
      setSelectedParameterInfo(null);
      setSelectediPropertyInfo(iProperty);
    }
  };

  const callInputDataStoreUpdateHandler = (
    property: { [key: string]: any },
    shouldDebounceUpdate?: boolean,
  ) => {
    if (selectedParameterInfo) {
      if (shouldDebounceUpdate) {
        _callDebounceInputValueUpdate(selectedParameterInfo, property);
      } else {
        handleSelectedInputDataStoreUpdate(selectedParameterInfo, property);
        setSelectedParameterInfo({ ...selectedParameterInfo, ...property });
      }
      // If is iProperty selected, the only field that can be updated is `label`.
      // So it needs to be debounced.
    } else if (selectediPropertyInfo) {
      _callDebounceInputValueUpdate(selectediPropertyInfo, property);
    }
  };

  const handleParameterMoveUp =
    (parameter: DraftTemplateInputParameter, parameterIndex: number) =>
    (event: React.MouseEvent<SVGSVGElement>) => {
      // Prevent onClick() event being triggered.
      event.stopPropagation();
      // Move the top selected parameter up
      if (parameterIndex === 0) {
        logger.warn(text.firstParameterUnableToMoveUp, { selectedParameters, parameter });
        showNotification({
          message: text.firstParameterUnableToMoveUp,
          status: NOTIFICATION_STATUSES.WARNING,
        });
        return;
      }

      // Move up
      const reorderedParameters: DraftTemplateInputParameter[] = cloneDeep(selectedParameters);
      // Destination index
      const destinationIndex: number = parameterIndex - 1;
      // Swap positions
      [reorderedParameters[parameterIndex], reorderedParameters[destinationIndex]] = [
        reorderedParameters[destinationIndex],
        reorderedParameters[parameterIndex],
      ];
      // Update parameters in data store
      addCurrentDraftParameters(reorderedParameters);
    };
  const handleParameterMoveDown =
    (parameter: DraftTemplateInputParameter, parameterIndex: number) =>
    (event: React.MouseEvent<SVGSVGElement>) => {
      // Prevent onClick() event being triggered.
      event.stopPropagation();
      // Move the bottom selected parameter down
      if (parameterIndex === selectedParameters.length - 1) {
        logger.warn(text.lastParameterUnableToMoveDown, { selectedParameters, parameter });
        showNotification({
          message: text.lastParameterUnableToMoveDown,
          status: NOTIFICATION_STATUSES.WARNING,
        });
        return;
      }

      // Move down
      const reorderedParameters: DraftTemplateInputParameter[] = cloneDeep(selectedParameters);
      // Destination index
      const destinationIndex: number = parameterIndex + 1;
      // Swap positions
      [reorderedParameters[parameterIndex], reorderedParameters[destinationIndex]] = [
        reorderedParameters[destinationIndex],
        reorderedParameters[parameterIndex],
      ];
      // Update parameters in data store
      addCurrentDraftParameters(reorderedParameters);
    };

  const setCodeblocksWorkspaceToUndefined = () => {
    setCurrentDraftCodeBlocksWorkspace(undefined);
  };

  return {
    selectedInput,
    selectedParameterInfo,
    selectediPropertyInfo,
    handleChangeSelectedInputs,
    handleParameterSelection,
    handleIPropertySelection,
    callInputDataStoreUpdateHandler,
    handleParameterMoveUp,
    handleParameterMoveDown,
    isCodeblocksWorkspaceUndefined: isUndefined(currentDraft.codeBlocksWorkspace),
    setCodeblocksWorkspaceToUndefined,
  };
};

export default useEditInputs;
