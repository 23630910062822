import React from 'react';
import text from '../../../Common/global/text/text.json';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  RowItem,
} from '../../../Common/global/styles/Common/Common.styles';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { TextInput } from '@adsk/alloy-react';

interface TextInputSectionProps {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  defaultValue: string;
  visible: boolean;
  readOnly: boolean;
}

const TextInputSection: React.FC<TextInputSectionProps> = ({
  callInputDataStoreUpdateHandler,
  defaultValue,
  visible,
  readOnly,
}) => {
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <InputFormField label={text.inputLabelDefaultValue} labelVariant="top" width="30%">
            <TextInput type="text" value={defaultValue} readOnly disabled></TextInput>
          </InputFormField>
        </RowItem>
      </FieldsetRow>
    </FieldSetContainer>
  );
};

export default TextInputSection;
