import { Icon } from '@adsk/alloy-react';
import styled from 'styled-components';

export const MIDTabsWrapper = styled.ul`
  list-style: none;
  padding: 0 0 ${({ theme }) => `${theme.vars.paddingBase}px`} 0;
  margin: 0 ${({ theme }) => `${theme.vars.marginBase}px`}
    ${({ theme }) => `${theme.vars.marginBase / 2}px`}
    ${({ theme }) => `${theme.vars.marginBase}px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal200};
`;

export const StyledMIDTab = styled.li<{ label: string }>`
  display: inline;
  margin-right: ${({ theme }) => `${theme.vars.marginBase * 3}px`};
  padding-bottom: ${({ theme }) => `${theme.vars.paddingBase}px`};
  cursor: pointer;
  ${({ theme }) => theme.typography.bodyMedium}

  transition: box-shadow 0.3s;

  &.active {
    ${({ theme }) => theme.typography.bodyMediumBold}
    box-shadow: 0px 4px 2px -2px ${({ theme }) => theme.colors.adskBlue500};
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.charcoal400};
    cursor: auto;
  }
`;

export const MIDTabIcon = styled(Icon)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  vertical-align: middle;
`;

export const MIDTabTooltipWrapper = styled.div`
  display: inline-block;
`;
