import {
  InventorParameter,
  InventorProperties,
  IProperty,
} from '../../../../../lib/interfaces/inventorProperties';
import text from '../../../../../Common/global/text/text.json';
import { getPartOrAssemblyProperties } from '../../../../../lib/utils/inventor';

export const fetchAllInventorData = async (iamFileFullPath: string): Promise<InventorProperties> =>
  getPartOrAssemblyProperties(iamFileFullPath);

export const filterParameters = (
  inputsData: InventorParameter[],
  filter: string,
  selectedParametersIds: { [key: string]: boolean },
): InventorParameter[] => {
  let filteredData: InventorParameter[] = [];
  switch (filter) {
    // If the filter option is All, we return all data
    case text.inputsFilterOptionAll:
      filteredData = inputsData;
      break;
    // If the filter option is Key, we should only return Key parameters
    case text.inputsFilterOptionKey:
      filteredData = inputsData.filter((param) => param.isKey);
      break;
    // If the filter option is Model, we should only return Model parameters
    case text.inputsFilterOptionModel:
      filteredData = inputsData.filter(
        (param) => param.parameterType === text.inputsFilterOptionModel,
      );
      break;
    // If the filter option is user, we should only return user parameters
    case text.inputsFilterOptionUser:
      filteredData = inputsData.filter(
        (param) => param.parameterType === text.inputsFilterOptionUser,
      );
      break;
    // If the filter option is Selected, then only return selected data
    case text.inputsFilterOptionSelected:
      filteredData = inputsData.filter((data: InventorParameter) =>
        Object.keys(selectedParametersIds).includes(data.name),
      );
      break;
    default:
      break;
  }
  return filteredData;
};

export const filterIProperties = (
  inputsData: IProperty[],
  filter: string,
  selectedIPropertiesIds: { [key: string]: boolean },
): IProperty[] => {
  let filteredData: IProperty[] = [];
  switch (filter) {
    // If the filter option is All, we return all data
    case text.inputsFilterOptionAll:
      filteredData = inputsData;
      break;
    // If the filter option is Selected, then only return selected data
    case text.inputsFilterOptionSelected:
      filteredData = inputsData.filter((data: IProperty) =>
        Object.keys(selectedIPropertiesIds).includes(data.id),
      );
      break;
    // If the filter option is With value - this only happens on iProperties inputs, we should
    // only return iProperties with value
    case text.inputsFilterOptionWithValue:
      filteredData = inputsData.filter((prop) => !!prop.value);
      break;
    default:
      break;
  }
  return filteredData;
};
