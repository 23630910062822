import { Button, Icon } from '@adsk/alloy-react';
import React from 'react';
import {
  SidebarComponent,
  SidebarButton,
  SidebarMenu,
  SidebarItem,
} from '../../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../../Common/global/text/text.json';
import {
  DraftTemplateInputParameter,
  DraftTemplateInputProperties,
  DraftTemplateIProperty,
} from '../../../../../../lib/interfaces/templates';
import { ItemCount } from '../EditInputs.styles';
import { MoveUpDown } from './MoveUpDown';

export interface EditInputsSidebarProps {
  selectedInput: DraftTemplateInputProperties;
  selectedParameterInfo: DraftTemplateInputParameter | null;
  selectediPropertyInfo: DraftTemplateIProperty | null;
  handleChangeSelectedInputs: () => void;
  handleParameterSelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleIPropertySelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleParameterMoveUp: (
    parameter: DraftTemplateInputParameter,
    parameterIndex: number,
  ) => (event: React.MouseEvent<SVGSVGElement>) => void;
  handleParameterMoveDown: (
    parameter: DraftTemplateInputParameter,
    parameterIndex: number,
  ) => (event: React.MouseEvent<SVGSVGElement>) => void;
  isFormDisabled?: boolean;
}

export const EditInputsSidebar: React.FC<EditInputsSidebarProps> = ({
  selectedInput,
  selectedParameterInfo,
  selectediPropertyInfo,
  handleChangeSelectedInputs,
  handleParameterSelection,
  handleIPropertySelection,
  handleParameterMoveDown,
  handleParameterMoveUp,
}): JSX.Element => {
  const countSelectedItems = (): number =>
    selectedInput.iProperties.length + selectedInput.parameters.length;

  const handlePreviewRules = () => undefined;

  return (
    <SidebarComponent>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handleChangeSelectedInputs}
        icon={Icon.TYPES.PLUS_CIRCLE_FILLED}
        variant={Button.VARIANTS.PRIMARY}
      >
        {text.buttonEditSelection}
      </SidebarButton>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handlePreviewRules}
        icon={Icon.TYPES.CALCULATOR}
      >
        {text.buttonPreviewRules}
      </SidebarButton>
      <SidebarMenu>
        {selectedInput.parameters.map((parameter: DraftTemplateInputParameter, index: number) => {
          const isSelected = selectedParameterInfo?.name === parameter.name;

          return (
            <SidebarItem
              showSelection={false}
              selected={isSelected}
              key={parameter.name}
              label={parameter.name}
              name={parameter.name}
              onClick={handleParameterSelection}
              rightSideLabel={
                <MoveUpDown
                  disabledUp={index === 0}
                  disabledDown={index === selectedInput.parameters.length - 1}
                  onClickUp={handleParameterMoveUp(parameter, index)}
                  onClickDown={handleParameterMoveDown(parameter, index)}
                ></MoveUpDown>
              }
            />
          );
        })}
        {selectedInput.iProperties.map((iProperty) => {
          const isSelected = !selectedParameterInfo && selectediPropertyInfo?.id === iProperty.id;
          return (
            <SidebarItem
              showSelection={false}
              selected={isSelected}
              key={iProperty.id}
              label={iProperty.name}
              icon={Icon.TYPES.PENCIL_FILLED}
              id={iProperty.id}
              onClick={handleIPropertySelection}
            />
          );
        })}
      </SidebarMenu>
      <ItemCount>{countSelectedItems()} selected</ItemCount>
    </SidebarComponent>
  );
};
