import React from 'react';
import {
  LeftSection,
  RightSection,
  Subtitle,
} from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';
import { Button } from '@adsk/alloy-react';
import { RightButton } from '../../../../Common/global/styles/Common/Common.styles';

const SelectDataset: React.FC<{ handleOpenSavedDraftsClick: () => void }> = ({
  handleOpenSavedDraftsClick,
}): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.subHeaderTitleSelectDataset}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton onClick={handleOpenSavedDraftsClick} size={Button.SIZES.SMALL}>
        {`${text.buttonSavedDrafts}`}
      </RightButton>
    </RightSection>
  </>
);

export default SelectDataset;
