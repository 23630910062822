import { StateReducer } from '@adsk/alloy-react-table/es/stateReducer';
import React, { ReactNode, useEffect, useState } from 'react';
import { TableInstance } from 'react-table';
import { actions, Column, SetTableOptions, TableSkeletonLoadingRow } from '@adsk/alloy-react-table';
import { TableOptions } from 'react-table';
import { InventorParameter, IProperty } from '../../../../../lib/interfaces/inventorProperties';
import MIDEmptyState from '../../../../../Common/components/EmptyState/MIDEmptyState';
import text from '../../../../../Common/global/text/text.json';
import { renderEmptyStateArgs } from '../../../../../Common/global/types';
import { StyledSkeletonLoadingVertebra } from '../../../../../Common/global/styles/Common/Common.styles';

export interface UseInputsTableState {
  tableData: InventorParameter[] | IProperty[];
  tableColumns: Column<Record<string, InventorParameter>>[] | Column<Record<string, IProperty>>[];
  selectedIds: {};
  setTableOptions: SetTableOptions<Record<string, any>>;
  handleOnAction: (reducer: StateReducer<Record<string, any>>) => void;
  renderEmptyParametersState: ({ table }: renderEmptyStateArgs) => ReactNode;
  renderEmptyiPropertiesState: ({ table }: renderEmptyStateArgs) => ReactNode;
}

export interface UseInputsTableArgs {
  initialTableData: (InventorParameter[] | IProperty[]) | null;
  rowIdKey: string;
  tableColumns: Column<Record<string, InventorParameter>>[] | Column<Record<string, IProperty>>[];
  setSelectedIds: (ids: { [key: string]: boolean }) => void;
  filterFunc: Function;
  selectedIds: { [key: string]: boolean };
  currentFilter: string;
  isFetching: boolean;
}

export const useInputsTable = ({
  initialTableData,
  rowIdKey,
  tableColumns,
  selectedIds,
  setSelectedIds,
  currentFilter,
  filterFunc,
  isFetching,
}: UseInputsTableArgs): UseInputsTableState => {
  const [tableData, setTableData] = useState<InventorParameter[] | IProperty[]>(
    initialTableData || [],
  );
  // This function is used to set custom row id
  // By default, table component is using row index (0,1,2...) as rowId.
  const setTableOptions: SetTableOptions<Record<string, any>> = (
    opts: TableOptions<Record<string, any>>,
  ): TableOptions<Record<string, any>> => ({
    ...opts,
    getRowId: (row: any) => row[rowIdKey],
  });

  // When user toggle the checkbox on each row, it will fire this event.
  // There will more action types to be used for different scenarios.
  // For our use case, we only care about toggleRowSelected and toggleAllRowsSelected.
  const handleOnAction = (reducer: StateReducer<Record<string, any>>) => {
    if ([actions.toggleRowSelected, actions.toggleAllRowsSelected].includes(reducer.action.type)) {
      setSelectedIds(reducer.state.selectedRowIds);
    }
  };

  useEffect(() => {
    if (initialTableData) {
      const filteredData = filterFunc(initialTableData, currentFilter, selectedIds);
      setTableData(filteredData);
    }
  }, [currentFilter, filterFunc, initialTableData, selectedIds]);

  const renderTableLoading = (table: TableInstance): JSX.Element => (
    <TableSkeletonLoadingRow
      visibleColumns={table.visibleColumns}
      renderCell={() => <StyledSkeletonLoadingVertebra />}
    />
  );

  const renderEmptyParametersState = ({ table }: renderEmptyStateArgs): JSX.Element => {
    if (isFetching) {
      return renderTableLoading(table);
    }
    return <MIDEmptyState title={text.EmptyStateNoParameters} />;
  };

  const renderEmptyiPropertiesState = ({ table }: renderEmptyStateArgs): JSX.Element => {
    if (isFetching) {
      return renderTableLoading(table);
    }
    return <MIDEmptyState title={text.EmptyStateNoIProperties}></MIDEmptyState>;
  };

  return {
    tableData,
    tableColumns,
    selectedIds,
    setTableOptions,
    handleOnAction,
    renderEmptyParametersState,
    renderEmptyiPropertiesState,
  };
};
