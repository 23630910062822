import React from 'react';
import MultiValueList from '../../../Common/components/MultiValueList/MultiValueList';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  RowItem,
} from '../../../Common/global/styles/Common/Common.styles';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';

export interface MultiValueListSectionProps {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  items: string[] | number[];
  value: string | number | undefined;
  visible: boolean;
  readOnly: boolean;
  disableInput: boolean;
}

export const MultiValueListSection: React.FC<MultiValueListSectionProps> = ({
  callInputDataStoreUpdateHandler,
  items,
  value,
  visible,
  readOnly,
  disableInput,
}) => {
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <InputFormField label={`Values (${items.length})`} labelVariant={'top'} width={'100%'}>
            <MultiValueList
              items={items}
              value={value}
              readOnly={false}
              onSelectionChange={(newValue: string | number) =>
                !disableInput && callInputDataStoreUpdateHandler({ value: newValue })
              }
            />
          </InputFormField>
        </RowItem>
      </FieldsetRow>
    </FieldSetContainer>
  );
};
