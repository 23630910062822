import { Button, ICON_TYPES } from '@adsk/alloy-react';
import React from 'react';
import { MiddleButton, MiddleSection } from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { DraftTemplate, OutputType } from '../../../lib/interfaces/templates';
import SummaryTable, {
  SummaryTableRow,
} from '../../../Common/components/SummaryTable/SummaryTable';
import { CompleteDialog, TableTitle, TemplateSummary } from './Publishing.styles';

export interface PublishingCompleteProps {
  currentDraft: DraftTemplate;
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}

export const PublishingComplete: React.FC<PublishingCompleteProps> = ({
  currentDraft,
  handleOpenSavedDraftsClick,
}): JSX.Element => {
  // Currently only supporting RFA
  const extractRFAOutputType = () =>
    currentDraft.outputs.filter((output) => output.type === OutputType.RFA)[0];

  const templateSummaryData: SummaryTableRow[] = [
    {
      title: text.publishTemplateName,
      value: currentDraft.name,
    },
    {
      title: text.publishTemplateTopLevelAssembly,
      value: currentDraft.assembly,
    },
    {
      title: text.publishTemplateParametersCount,
      value: currentDraft.parameters.length.toString(),
    },
    {
      title: text.publishTemplateIPropertiesCount,
      value: currentDraft.iProperties.length.toString(),
    },
    {
      title: text.publishTemplateOutputRepresentation,
      value: extractRFAOutputType().type + ' - ' + extractRFAOutputType().options?.modelStates?.[0],
    },
    {
      title: text.publishTemplateRevitCategory,
      value: extractRFAOutputType().options?.category || '',
    },
    {
      title: text.publishTemplateRevitFamily,
      value: extractRFAOutputType().options?.family || '',
    },
  ];

  const publishLocationData: SummaryTableRow[] = [
    {
      title: text.publishLocationAccount,
      value: currentDraft.account.name,
    },
    {
      title: text.publishLocationProject,
      value: currentDraft.project.name,
    },
    {
      title: text.publishLocationFolder,
      value: currentDraft.folder.name,
    },
  ];

  return (
    <>
      <CompleteDialog>
        <p>
          <strong>{currentDraft.name}</strong> {text.publishingWasPublishedTo}{' '}
          <strong>
            {currentDraft.account.name} / {currentDraft.project.name} / {currentDraft.folder.name}.
          </strong>
        </p>
        <MiddleSection>
          <MiddleButton
            icon={ICON_TYPES.BOOKMARK_FILLED}
            size={Button.SIZES.SMALL}
            onClick={handleOpenSavedDraftsClick}
          >
            {text.buttonSavedDrafts}
          </MiddleButton>
        </MiddleSection>
      </CompleteDialog>
      <TemplateSummary>
        <TableTitle>{text.publishSummaryTableTitle}</TableTitle>
        <SummaryTable data={templateSummaryData} />
      </TemplateSummary>
      <TemplateSummary>
        <TableTitle>{text.publishLocationTableTitle}</TableTitle>
        <SummaryTable data={publishLocationData} />
      </TemplateSummary>
    </>
  );
};
