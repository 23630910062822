import '@blockly/theme-modern';

const blocklyConfig = {
  collapse: true,
  comments: true,
  css: true,
  disable: false,
  horizontal: false,
  maxBlocks: Infinity,
  oneBasedIndex: true,
  readOnly: false,
  renderer: 'thrasos',
  rtl: false,
  scrollbars: true,
  sounds: true,
  theme: 'modern',
  toolboxPosition: 'start',
  trashcan: true,
  zoom: {
    controls: true,
  },
};

export default blocklyConfig;
