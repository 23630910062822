import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import Blockly from 'blockly';
import blocklyConfig from './blocklyConfig';
import toolbox from './toolbox';
import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import DataContext from '../../context/DataStore/Data.context';
import text from '../../../Common/global/text/text.json';
import DCDInputs from './Blocks/DCDInputs';
import { javascriptGenerator } from 'blockly/javascript';
import { blocklyToolboxInputsCategory, BLOCKLY_EVENTS_TO_UPDATE } from './constants';
import { initializeBlocklyMutators } from './Blocks/mutators';
import { initializeBlocklyExtensions } from './Blocks/extensions';
import './Blocks/javascriptBlocks';
import { BlocklyEvent } from './types';
import { SerializedBlocklyWorkspaceState } from '../../../lib/interfaces/templates';

//Initialize custom blocks definition
Blockly.defineBlocksWithJsonArray(DCDInputs);

interface useBlocklyModuleProps {
  initialState?: SerializedBlocklyWorkspaceState;
  shouldCleanupBlocks?: boolean;
}

interface useBlocklyModuleReturn {
  ref: (node: HTMLDivElement) => void;
  getState: () => SerializedBlocklyWorkspaceState;
  getCode: (highlightBlocks?: boolean) => string;
  blocklyWorkspace?: Blockly.WorkspaceSvg;
}

const useBlocklyModule = ({
  initialState,
  shouldCleanupBlocks,
}: useBlocklyModuleProps): useBlocklyModuleReturn => {
  const { currentDraft, setCurrentDraftRule, setCurrentDraftCodeBlocksWorkspace } =
    useContext(DataContext);

  const blocklyRef = useRef<Blockly.WorkspaceSvg>();
  const [isBlocklyWorkspaceInitialized, setBlocklyWorkspaceInitialized] = useState(false);
  const [isInitialStateLoadStepDone, setInitialStateLoadStepDone] = useState(false);

  const { showNotification } = useNotifications();

  //Update Data store
  const handleUpdateDataStore = useCallback(
    (event: BlocklyEvent): void => {
      if (BLOCKLY_EVENTS_TO_UPDATE.includes(event.type)) {
        // initial coordinates are set to (0,0) before workspace.cleanup()
        // we don't update draft if cleanup is called
        if (
          event.type === Blockly.Events.BLOCK_MOVE &&
          event.oldCoordinate?.x === 0 &&
          event.oldCoordinate?.y === 0
        ) {
          return;
        }
        // Save rule in dataStore
        setCurrentDraftRule({ key: 'currentRule', code: getCode() });
        // Save Blockly Workspace state  in dataStore
        setCurrentDraftCodeBlocksWorkspace(getState());
      }
    },
    [setCurrentDraftRule, setCurrentDraftCodeBlocksWorkspace],
  );

  //Check for initial state load
  const handleInitialStateLoad = useCallback(
    (event: BlocklyEvent): void => {
      // Don't save Blockly Workspace if it was initialized for the first time
      if (event.type === Blockly.Events.FINISHED_LOADING) {
        //...and we set Step to Done (true). We do not want to load it again after component mounted
        // It could happen when codeBlocksWorkspace is updated in dataStore]
        setInitialStateLoadStepDone(true);

        //Once the blocks are loaded, add update data store listener
        blocklyRef.current?.addChangeListener(handleUpdateDataStore);
      }
    },
    [setInitialStateLoadStepDone, handleUpdateDataStore],
  );

  // Initialize Blockly Workspace
  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node && !isBlocklyWorkspaceInitialized) {
        //Initialize custom mutators and extensions
        initializeBlocklyMutators();
        initializeBlocklyExtensions(currentDraft.parameters);

        const workspace = Blockly.inject(node, {
          ...blocklyConfig,
          toolbox,
        });

        workspace.registerToolboxCategoryCallback(blocklyToolboxInputsCategory, () =>
          DCDInputs.map((block) => ({ kind: 'block', type: block.type })),
        );

        blocklyRef.current = workspace;

        //Initialize Events listeners
        workspace.addChangeListener(handleInitialStateLoad);

        // Initialize Blockly Workspace and its Mutators, Extensions adn Events just once
        setBlocklyWorkspaceInitialized(true);
        return () => {
          //Clean up Blockly
          if (workspace) {
            workspace.dispose();
          }
        };
      }
    },
    [currentDraft.parameters, isBlocklyWorkspaceInitialized, handleInitialStateLoad],
  );

  // Load initial state, if provided
  useEffect(() => {
    // This side effect should run once Blockly workspace has been initialized
    if (isBlocklyWorkspaceInitialized) {
      // Once Blockly workspace has been initialized,
      // we check if we have initial state to load just once...
      if (!isInitialStateLoadStepDone && initialState && blocklyRef.current) {
        try {
          Blockly.serialization.workspaces.load(initialState, blocklyRef.current);
          if (shouldCleanupBlocks) {
            blocklyRef.current.cleanUp();
          }
        } catch (e) {
          // If initial state is invalid, start with an empty state
          showNotification({
            message: text.blocklyInvalidInitialState,
            status: NOTIFICATION_STATUSES.ERROR,
          });
        }
      }
    }
  }, [
    initialState,
    isInitialStateLoadStepDone,
    isBlocklyWorkspaceInitialized,
    showNotification,
    shouldCleanupBlocks,
  ]);

  // function to return current blockly state
  const getState = () => {
    if (blocklyRef.current) {
      return Blockly.serialization.workspaces.save(blocklyRef.current);
    }
    return {};
  };

  // function to return current blockly code
  const getCode = (highlightBlocks?: boolean) => {
    if (highlightBlocks) {
      javascriptGenerator.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
      javascriptGenerator.addReservedWords('highlightBlock');
    } else {
      javascriptGenerator.STATEMENT_PREFIX = '';
    }
    return javascriptGenerator.workspaceToCode(blocklyRef.current);
  };

  return {
    ref,
    getState,
    getCode,
    blocklyWorkspace: blocklyRef.current,
  };
};

export default useBlocklyModule;
