import { TextInput, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  RowItem,
  TooltipIcon,
  TooltipWrapper,
} from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';
import { InputWrapper, NumericFieldsWrapper } from './NumericInputSection.styles';
import { useNumericInput } from './useNumericInput';

interface NumericInputSectionProps {
  defaultValue: string;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  min: number | string;
  max: number | string;
  increment: number | string;
  visible: boolean;
  readOnly: boolean;
}

const NumericInputSection: React.FC<NumericInputSectionProps> = ({
  defaultValue,
  callInputDataStoreUpdateHandler,
  min,
  max,
  increment,
  visible,
  readOnly,
}) => {
  const {
    localMin,
    localMax,
    localIncrement,
    maxTooltipContent,
    maxTooltipIconType,
    minTooltipContent,
    minTooltipIconType,
    incrementTooltipIconType,
    incrementTooltipContent,
    handleMinimumValueChange,
    handleMaximumValueChange,
    handleIncrementChange,
    handleBlur,
  } = useNumericInput({
    callInputDataStoreUpdateHandler,
    min,
    max,
    increment,
    readOnly,
    defaultValue,
  });
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });
  const isInputDisabled = localReadOnly || !localShowInputInForm;

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <InputFormField label={text.inputLabelDefaultValue} labelVariant="top" width="30%">
            <TextInput type="text" readOnly disabled value={defaultValue}></TextInput>
          </InputFormField>
        </RowItem>
        <NumericFieldsWrapper>
          <InputFormField label={text.inputLabelMinimumValue} labelVariant="top" width="100%">
            <InputWrapper>
              <TextInput
                type={isInputDisabled ? 'text' : 'number'}
                value={localMin}
                onChange={handleMinimumValueChange}
                disabled={isInputDisabled}
                onBlur={handleBlur}
              />
              {minTooltipContent && (
                <TooltipWrapper>
                  <Tooltip content={minTooltipContent}>
                    <TooltipIcon type={minTooltipIconType} size={17} fr={0} />
                  </Tooltip>
                </TooltipWrapper>
              )}
            </InputWrapper>
          </InputFormField>
          <InputFormField label={text.inputLabelMaximumValue} labelVariant="top" width="100%">
            <InputWrapper>
              <TextInput
                type={isInputDisabled ? 'text' : 'number'}
                value={localMax}
                onChange={handleMaximumValueChange}
                disabled={isInputDisabled}
                onBlur={handleBlur}
              />
              {maxTooltipContent && (
                <TooltipWrapper>
                  <Tooltip content={maxTooltipContent}>
                    <TooltipIcon type={maxTooltipIconType} size={17} fr={0} />
                  </Tooltip>
                </TooltipWrapper>
              )}
            </InputWrapper>
          </InputFormField>
          <InputFormField label={text.inputLabelIncrementedBy} labelVariant="top" width="95%">
            <InputWrapper>
              <TextInput
                type={isInputDisabled ? 'text' : 'number'}
                value={localIncrement}
                onChange={handleIncrementChange}
                disabled={isInputDisabled}
                onBlur={handleBlur}
              />
              {incrementTooltipContent && (
                <TooltipWrapper>
                  <Tooltip content={incrementTooltipContent}>
                    <TooltipIcon type={incrementTooltipIconType} size={17} fr={0} />
                  </Tooltip>
                </TooltipWrapper>
              )}
            </InputWrapper>
          </InputFormField>
        </NumericFieldsWrapper>
      </FieldsetRow>
    </FieldSetContainer>
  );
};

export default NumericInputSection;
