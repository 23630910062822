import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react-notification';
import { useContext } from 'react';
import { useAsyncFetchData } from '../../Common/global/hooks/http/hooks';
import logger from '../../Common/global/logger';
import text from '../../Common/global/text/text.json';
import { DraftTemplate } from '../../lib/interfaces/templates';
import { deleteDrafts, getDrafts } from '../../lib/utils/drafts';
import DataContext from '../context/DataStore/Data.context';
import NavigationContext from '../context/NavigationStore/Navigation.context';
import { Screens } from '../context/NavigationStore/navigationStore';

interface UseDraftTemplateScreenProps {
  drafts: DraftTemplate[] | null;
  loading: boolean;
  error: Error | null;
  handleEditTemplateClick: (draftTemplate: DraftTemplate) => void;
  handleDeleteTemplatesClick: (draftIds: string[]) => Promise<void>;
}

const useDraftTemplateScreen = (): UseDraftTemplateScreenProps => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { setCurrentDraft } = useContext(DataContext);
  const { showNotification } = useNotifications();

  const {
    data: drafts,
    setData: setDrafts,
    loading,
    error,
  } = useAsyncFetchData<DraftTemplate[]>(getDrafts);

  const handleEditTemplateClick = (draftTemplate: DraftTemplate) => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
    setCurrentDraft(draftTemplate);
  };

  const handleDeleteTemplatesClick = async (draftIds: string[]) => {
    try {
      const currentDrafts = await deleteDrafts(draftIds);
      setDrafts(currentDrafts);
      showNotification({
        message: text.notificationDeleteDraftSuccess,
        status: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err) {
      showNotification({
        message: text.notificationDeleteDraftFailed,
        status: NOTIFICATION_STATUSES.ERROR,
      });
      logger.error('Failed to delete templates!', { draftIds });
      return Promise.reject(err);
    }
  };
  return {
    drafts,
    loading,
    error,
    handleEditTemplateClick,
    handleDeleteTemplatesClick,
  };
};

export default useDraftTemplateScreen;
