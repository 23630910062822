import React from 'react';
import { Column, DataGridRows, Row } from '../Tables.types';
import text from '../../../../../Common/global/text/text.json';
import { DataGridWrapper } from '../TablesTab.styles';
import { SidebarTitle } from '../../../../../Common/global/styles/Common/Common.styles';

interface TableDataGridProps {
  sheets: string[];
  currentSheet: string;
  dataGridRows: DataGridRows;
  columns: Column[];
  setDataGridRows: React.Dispatch<React.SetStateAction<DataGridRows>>;
}

const TableDataGrid: React.FC<TableDataGridProps> = ({
  columns,
  currentSheet,
  dataGridRows,
  setDataGridRows,
}): JSX.Element => (
  <>
    <SidebarTitle>
      {text.dependencies} <strong>{currentSheet}</strong>
    </SidebarTitle>
    <DataGridWrapper
      className={'rdg-light'}
      columns={columns}
      rows={dataGridRows.data}
      onRowsChange={(rows) => setDataGridRows({ header: dataGridRows.header, data: rows as Row[] })}
    />
  </>
);

export default TableDataGrid;
