import { Checkbox, CheckboxState } from '@adsk/alloy-react';
import React from 'react';
import { CheckboxLabel, CheckboxLabelText } from './CheckboxWithLabelText.styles';

interface CheckboxWithLabelTextProps {
  checked: boolean;
  handleCheckboxChange: (state: CheckboxState, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWithLabelText: React.FC<CheckboxWithLabelTextProps> = ({
  checked,
  handleCheckboxChange,
  children,
}) => (
  <CheckboxLabel>
    <Checkbox checked={checked} onChange={handleCheckboxChange} />
    <CheckboxLabelText>{children}</CheckboxLabelText>
  </CheckboxLabel>
);

export default CheckboxWithLabelText;
