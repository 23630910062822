import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react-notification';
import { useContext, useEffect, useState } from 'react';
import { useAsyncFetchDataWithArgs } from '../../../../Common/global/hooks/http/hooks';
import logger from '../../../../Common/global/logger';
import { createFullPath, getPathSeparator } from '../../../../Common/global/path';
import text from '../../../../Common/global/text/text.json';
import { FileFilter } from '../../../../lib/interfaces/fileSystem';
import { getThumbnailImgPath } from '../../../../lib/utils/drafts';
import {
  checkAndGenerateThumnailInBase64,
  selectFile,
  selectFolder,
} from '../../../../lib/utils/filesystem';
import DataContext from '../../../context/DataStore/Data.context';
import TabProgressContext from '../../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../../types';
import { SourceModelData } from './SourceContent.types';

interface UseSourceContentState {
  isPreviewStep: boolean;
  isPreviewLoading: boolean;
  localTopLevelFolder: string;
  localRelativeInventorProjectPath: string;
  localRelativeAssemblyPath: string | undefined;
  localFullAssemblyPath: string;
  thumbnailInBase64: { name: string; base64: string } | null;
  handleSelectTopLevelFolderClick: () => Promise<void>;
  handleSelectInventorProjectClick: () => Promise<void>;
  handleSelectTopLevelAssemblyClick: () => Promise<void>;
  handleNextButtonClick: () => Promise<void>;
}

export const useSourceContent = (): UseSourceContentState => {
  const { currentDraft, setCurrentDraftName, setCurrentDraftSourceModel } = useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const { showNotification } = useNotifications();

  const { topLevelFolder, inventorProject, assembly, thumbnail } = currentDraft;

  const [localTopLevelFolder, setLocalTopLevelFolder] = useState(topLevelFolder);
  const [localRelativeInventorProjectPath, setLocalRelativeInventorProjectPath] =
    useState(inventorProject);
  const [localRelativeAssemblyPath, setLocalRelativeAssemblyPath] = useState(assembly);
  const [localFullAssemblyPath, setLocalFullAssemblyPath] = useState('');
  const [isPreviewStep, setPreviewStep] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();
  const { data: thumbnailInBase64, error: thumbnailError } = useAsyncFetchDataWithArgs<{
    name: string;
    base64: string;
  }>(checkAndGenerateThumnailInBase64, fetchDependencyList);

  useEffect(() => {
    if (topLevelFolder && assembly) {
      setLocalFullAssemblyPath(createFullPath(topLevelFolder, assembly));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (thumbnail && localFullAssemblyPath) {
      setFetchDependencyList([thumbnail, localFullAssemblyPath]);
    }
  }, [thumbnail, localFullAssemblyPath]);

  // error fetching thumbnail
  useEffect(() => {
    if (thumbnailError) {
      const errorMsg = `Error: Fail to fetch thumbnail image in base64 from the path (${thumbnail})!`;
      logger.error(errorMsg);
    }
  }, [thumbnail, thumbnailError]);

  const sourceContentTabProgress = tabProgress[TABS.SOURCE_CONTENT];

  const handleUpdateDataStore = (data: SourceModelData) => {
    const separator = getPathSeparator(data.topLevelFolder || localTopLevelFolder);
    const assemblyPathList = data.assembly?.split(separator) || [];
    const name = assemblyPathList[assemblyPathList.length - 1]
      .replace('.ipt', '')
      .replace('.iam', '');
    setCurrentDraftName(name);
    setCurrentDraftSourceModel(
      data.topLevelFolder,
      data.inventorProject,
      data.assembly,
      data.thumbnail || '',
    );
  };

  // Determine which view to show
  useEffect(() => {
    const sourceContentFormIsComplete = sourceContentTabProgress === TabProgress.COMPLETED;
    setPreviewStep(sourceContentFormIsComplete);
  }, [sourceContentTabProgress]);

  // Update local state with currentDraft values
  useEffect(() => {
    setLocalTopLevelFolder(topLevelFolder);
    setLocalRelativeInventorProjectPath(inventorProject);
    setLocalRelativeAssemblyPath(assembly);
    if (topLevelFolder && assembly) {
      setLocalFullAssemblyPath(createFullPath(topLevelFolder, assembly));
    }
  }, [topLevelFolder, inventorProject, assembly, thumbnail]);

  const handleSelectTopLevelFolderClick = async () => {
    const folder = await selectFolder();
    // TODO: Add notification if selectFolder returns empty string
    if (folder) {
      setLocalTopLevelFolder(folder);
    }
  };

  const extractRelativePath = (fullPath: string): string | undefined =>
    fullPath.replace(localTopLevelFolder, '');

  const handleSelectInventorProjectClick = async () => {
    const ipjFilter: FileFilter[] = [{ name: 'Project Files(*.ipj)', expression: '*.ipj' }];
    const inventorProject = await selectFile(ipjFilter, false, localTopLevelFolder);
    if (inventorProject.length) {
      const filename = extractRelativePath(inventorProject[0]);
      if (filename) {
        setLocalRelativeInventorProjectPath(filename);
      }
    }
  };

  const handleSelectTopLevelAssemblyClick = async () => {
    const inventorFilesFilter: FileFilter[] = [
      { name: 'Assembly Files (*.iam)', expression: '*.iam' },
      { name: 'Part Files (*.ipt)', expression: '*.ipt' },
    ];
    const assembly = await selectFile(inventorFilesFilter, false, localTopLevelFolder);
    if (assembly.length) {
      const filename = extractRelativePath(assembly[0]);
      if (filename) {
        setLocalRelativeAssemblyPath(filename);
        setLocalFullAssemblyPath(assembly[0]);
      }
    }
  };

  const handleNextButtonClick = async () => {
    try {
      // If topLevelFolder or Assembly are missing, something is wrong
      if (!localFullAssemblyPath) {
        const errorMsg = text.notificationTopLevelFolderAndAssemblyEmpty;
        logger.error(errorMsg);
        throw new Error(errorMsg);
      }

      setIsPreviewLoading(true);
      // Fetch thumbnail image from API services
      const thumbnailPath = await getThumbnailImgPath(localFullAssemblyPath);
      // If thumbnail request failed, show notification
      if (!thumbnailPath) {
        showNotification({
          message: text.notificationThumbnailFailed,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
      // Set current draft template
      handleUpdateDataStore({
        topLevelFolder: localTopLevelFolder,
        inventorProject: localRelativeInventorProjectPath,
        assembly: localRelativeAssemblyPath,
        ...(thumbnailPath && { thumbnail: thumbnailPath }),
      });
    } catch (err) {
      logger.error('Error: Fail to update data store on handleNextClick()');
      showNotification({
        message: text.notificationTopLevelFolderAndAssemblyEmpty,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    } finally {
      setIsPreviewLoading(false);
    }
  };

  return {
    localTopLevelFolder,
    localRelativeInventorProjectPath,
    localRelativeAssemblyPath,
    localFullAssemblyPath,
    isPreviewStep,
    thumbnailInBase64,
    isPreviewLoading,
    handleSelectTopLevelFolderClick,
    handleSelectInventorProjectClick,
    handleSelectTopLevelAssemblyClick,
    handleNextButtonClick,
  };
};

export default useSourceContent;
