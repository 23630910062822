import { Button, Icon } from '@adsk/alloy-react';
import React, { createRef } from 'react';
import text from '../../../../../Common/global/text/text.json';
import { FileInput, FormWrapper, TableRightButton, TableTextInput } from '../TablesTab.styles';

interface TopFolderSectionProps {
  tableName: string;
  handleNextClick: () => void;
  handleDeleteClick: () => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TableUpload: React.FC<TopFolderSectionProps> = ({
  tableName,
  handleNextClick,
  handleDeleteClick,
  handleFileSelect,
}): JSX.Element => {
  const fileInput = createRef<HTMLInputElement>();
  const handleSelectButtonClick = () => {
    fileInput.current?.click();
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleNextClick();
  };

  return (
    <>
      <TableTextInput
        type="text"
        placeholder={text.NotSelectedLabel}
        readOnly
        defaultValue={tableName}
      />
      {tableName ? (
        <FormWrapper onSubmit={handleSubmit}>
          <TableRightButton size={Button.SIZES.MEDIUM} onClick={handleSelectButtonClick}>
            {text.buttonReplace}
          </TableRightButton>
          <FileInput
            type="file"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleFileSelect}
            ref={fileInput}
          />
          <TableRightButton
            icon={Icon.TYPES.TRASH_CAN}
            size={Button.SIZES.MEDIUM}
            onClick={handleDeleteClick}
          ></TableRightButton>
          <TableRightButton
            type="submit"
            size={Button.SIZES.MEDIUM}
            variant={Button.VARIANTS.PRIMARY}
          >
            {text.buttonNext}
          </TableRightButton>
        </FormWrapper>
      ) : (
        <>
          <TableRightButton
            onClick={handleSelectButtonClick}
            variant={Button.VARIANTS.PRIMARY}
            size={Button.SIZES.MEDIUM}
          >
            {text.buttonSelect}
          </TableRightButton>
          <FileInput
            type="file"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleFileSelect}
            ref={fileInput}
          />
        </>
      )}
    </>
  );
};

export default TableUpload;
