type separator = '\\' | '/';

export const getPathSeparator = (path: string): separator => (/\\/.test(path) ? '\\' : '/');

export const createFullPath = (topLevelFolder: string, relativePathToFile: string): string => {
  const separator = getPathSeparator(topLevelFolder);
  const rawJoinedPath = [topLevelFolder, relativePathToFile].join(separator);
  const normalizedPath =
    separator === '\\' ? rawJoinedPath.replace(/\\\\/g, '\\') : rawJoinedPath.replace(/\/\//g, '/');
  return normalizedPath;
};
