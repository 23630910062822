import { DataCategory, uploadFile } from '../utils/cloudStorage';
import {
  DynamicContentInput,
  DynamicContentProduct,
  TemplateInputType,
} from '../interfaces/dynamicContent';
import {
  DraftTemplate,
  DraftTemplateInputParameter,
  DraftTemplateOutput,
  DraftTemplatePublishResult,
  OutputType,
  PublishStatus,
} from '../interfaces/templates';
import { generateOutputs } from './inventor';
import { draftToDCTemplate } from './drafts';
import { postProduct } from './products';
import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
  InventorOutputFileInfo,
  InventorOutputType,
} from '../interfaces/inventorAutomation';
import { postVariantToAPI } from './variants';
import { PostVariantOutput, PostVariantPayload, PostVariantInput } from '../interfaces/variants';
import { compressFolder, deleteFile } from './filesystem';

export const draftInputsToInventorInputs = (
  draftParams: DraftTemplateInputParameter[],
): InventorInput[] =>
  draftParams.map((draftParam) => {
    switch (draftParam.type) {
      case TemplateInputType.Boolean:
        return {
          name: draftParam.name,
          value: draftParam.value.toString(),
          isProperty: false,
        };

      case TemplateInputType.Text:
        return {
          name: draftParam.name,
          value: draftParam.value,
          isProperty: false,
        };

      case TemplateInputType.Numeric:
        return {
          name: draftParam.name,
          value: draftParam.value.toPrecision(17),
          isProperty: false,
        };

      case TemplateInputType.MultiValueText:
        return {
          name: draftParam.name,
          value: draftParam.value ?? '',
          isProperty: false,
        };

      case TemplateInputType.MultiValueNumeric:
        return {
          name: draftParam.name,
          value: draftParam.value?.toPrecision(17) ?? '',
          isProperty: false,
        };
    }
  });

export const draftOutputsToInventorOutputs = (
  draftOutputs: DraftTemplateOutput[],
): InventorOutput[] =>
  draftOutputs
    .filter((draftOutput) => draftOutput.type === OutputType.RFA)
    .map((draftOutput) => ({
      type: InventorOutputType.RFA,
      modelStates: draftOutput.options?.modelStates,
    }));

export const generateOutputFiles = async (
  draftTemplate: DraftTemplate,
): Promise<GenerateOutputsResult> => {
  const inventorInputs = draftInputsToInventorInputs(draftTemplate.parameters);
  const inventorOutputs = draftOutputsToInventorOutputs(draftTemplate.outputs);

  // Request a thumbnail image for the primary model state.
  // This will serve as the product thumbnail and match what is displayed in the UI.
  inventorOutputs.push({ type: InventorOutputType.THUMBNAIL, modelStates: ['[Primary]'] });

  const topFolderPath = draftTemplate.topLevelFolder;
  const documentFilePath = `${topFolderPath}${draftTemplate.assembly}`;

  const generateOutputsResult = await generateOutputs(
    topFolderPath,
    documentFilePath,
    inventorInputs,
    inventorOutputs,
  );

  return generateOutputsResult;
};

export const cleanupOutputs = async (outputFiles: InventorOutputFileInfo[]): Promise<void> => {
  await Promise.all(
    outputFiles.map(async (fileInfo) => {
      await deleteFile(fileInfo.filePath);
    }),
  );
};

// TODO: The value of a DynamicContentInput should not be potentially undefined.
// (Looks like this is still also used for draft templates mixing up separate concerns.)
export const dynamicContentInputsToVariantInputs = (
  dcInputs: DynamicContentInput[],
): PostVariantInput[] => dcInputs.map((dcInput) => ({ name: dcInput.name, value: dcInput.value! }));

/**
 * Publishes a draft template as dynamic content product.
 *
 * @param draftTemplate The draft template to be published.
 * @returns An object containing the result of the operation.
 */
export const publishProductFromDraft = async (
  draftTemplate: DraftTemplate,
): Promise<DraftTemplatePublishResult> => {
  let outputFiles: InventorOutputFileInfo[] = [];
  try {
    // generate the output files through local Inventor
    const generateOutputsResult = await generateOutputFiles(draftTemplate);

    if (!generateOutputsResult.success) {
      // TODO: need to define what to report in case of a failure
      throw `Failed to generate outputs: ${generateOutputsResult.report}`;
    }

    outputFiles = generateOutputsResult.outputFiles!;

    // upload the product thumbnail
    const thumbnailFilePath = outputFiles.find(
      (x) => x.type === InventorOutputType.THUMBNAIL,
    )!.filePath;

    const thumbnailObjectKey = await uploadFile(
      draftTemplate.project.id,
      thumbnailFilePath,
      DataCategory.Outputs,
      'image/bmp',
    );

    // upload the input dataset
    const zipFilePath = await compressFolder(draftTemplate.topLevelFolder);
    const datasetObjectKey = await uploadFile(
      draftTemplate.project.id,
      zipFilePath,
      DataCategory.Inputs,
    );

    //Delete zipfle
    await deleteFile(zipFilePath);

    // POST product
    const dynamicContentProduct: DynamicContentProduct = draftToDCTemplate(
      draftTemplate,
      thumbnailObjectKey,
      datasetObjectKey,
    );

    // remove unexpected fields, otherwise validation will fail
    const { tenancyId, contentId, ...postProductPayload } = dynamicContentProduct;
    const publishedProduct = await postProduct(tenancyId, postProductPayload);

    // POST variant
    try {
      const inputs = dynamicContentInputsToVariantInputs(publishedProduct.inputs);
      const outputs: PostVariantOutput[] = [];

      // add RFA output if this was requested
      const rfaOutput = publishedProduct.outputs.find((output) => output.type === 'RFA');

      if (rfaOutput) {
        const rfaFilePath = outputFiles.find((x) => x.type === InventorOutputType.RFA)!.filePath;
        const rfaObjectKey = await uploadFile(
          draftTemplate.project.id,
          rfaFilePath!,
          DataCategory.Outputs,
        );

        outputs.push({
          type: 'RFA',
          urn: rfaObjectKey,
          modelState: rfaOutput.options!.modelStates![0],
        });
      }

      const postVariantPayload: PostVariantPayload = {
        inputs,
        outputs,
      };

      await postVariantToAPI(
        publishedProduct.tenancyId,
        publishedProduct.contentId!,
        postVariantPayload,
      );
    } catch {
      // an error in POST variant won't cause publishing to fail
    }

    // Clean up the outputs
    await cleanupOutputs(outputFiles);

    return {
      status: PublishStatus.COMPLETE,
      publishedProduct,
    };
  } catch (err) {
    // TODO: this is unused atm; for now just track the error that caused publishing to fail
    const errorMessage =
      err instanceof Error
        ? (err as Error).message
        : typeof err === 'string'
        ? err
        : 'Unknown error';

    // Clean up the outputs
    await cleanupOutputs(outputFiles);

    return {
      status: PublishStatus.FAILURE,
      errorMessage,
    };
  }
};
