import { Accordion, RadioButton, RadioGroup, TextInput, Toggle } from '@adsk/alloy-react';
import styled from 'styled-components';

export const OutputContainer = styled.fieldset`
  border: none;
  background-color: ${({ theme }) => theme.colors.charcoal100};
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const OutputRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const OutputHeader = styled.div`
  padding: ${({ theme }) => `${2 * theme.vars.paddingBase}px`}
    ${({ theme }) => `${theme.vars.paddingBase}px`} ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${2 * theme.vars.paddingBase}px`};
  width: 50%;
`;

export const OutputInputControl = styled.div`
  padding: 0 ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${2 * theme.vars.paddingBase}px`};
  width: 50%;
`;

export const OutputSmallText = styled.small`
  padding: 0 ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${2 * theme.vars.paddingBase}px`};
`;

export const RadioGroupWrapper = styled(RadioGroup)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${theme.vars.paddingBase}px`} ${({ theme }) => `${theme.vars.paddingBase}px`}
    ${({ theme }) => `${2 * theme.vars.paddingBase}px`};
`;

export const RadioButtonField = styled.div`
  display: flex;
  width: 50%;
`;

export const RadioButtonControl: typeof RadioButton = styled(RadioButton)`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const ToggleControl: typeof Toggle = styled(Toggle)`
  margin: 0 ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const OutputTextInput = styled(TextInput)`
  width: ${({ theme }) => `${theme.vars.wideInputControl}px`};
`;

export const AccordianItemLabel = styled.div`
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 4px;
  width: 40px;
  text-align: center;
`;

export const AccordianItemLabelGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AccordionWrap = styled(Accordion)`
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.outputsAccordionHeight}px`}
  );
  overflow: scroll;
`;

export const OutputRowHeader = styled.h4`
  margin: 0px;
`;
