import { Button } from '@adsk/alloy-react';
import React from 'react';
import { LabelText, MIDFormField } from '../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../Common/global/text/text.json';
import { SourceContentRightButton, SourceContentTextInput } from '../SourceContentTab.styles';

interface AssemblySectionProps {
  topLevelAssembly: string | undefined;
  handleSelectTopLevelAssemblyClick: () => Promise<void>;
  iPJ: string;
}

const AssemblySection: React.FC<AssemblySectionProps> = ({
  topLevelAssembly,
  handleSelectTopLevelAssemblyClick,
  iPJ,
}): JSX.Element => (
  <MIDFormField required label={text.sourceContentTopLevelAssemblyLabel} labelVariant="top">
    <LabelText>
      <small>{text.sourceContentTopLevelAssemblyComment}</small>
    </LabelText>
    <SourceContentTextInput
      type="text"
      placeholder="Not selected"
      data-testid="top-assembly-input"
      readOnly
      value={topLevelAssembly}
    />
    {topLevelAssembly ? (
      <SourceContentRightButton
        data-testid="top-assembly-replace-button"
        onClick={handleSelectTopLevelAssemblyClick}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonReplace}
      </SourceContentRightButton>
    ) : (
      <SourceContentRightButton
        data-testid="top-assembly-select-button"
        onClick={handleSelectTopLevelAssemblyClick}
        variant={iPJ ? Button.VARIANTS.PRIMARY : Button.VARIANTS.SECONDARY}
        size={Button.SIZES.MEDIUM}
      >
        {text.buttonSelect}
      </SourceContentRightButton>
    )}
  </MIDFormField>
);

export default AssemblySection;
