import React from 'react';
import { MiddleSection } from '../../../../Common/global/styles/Common/Common.styles';
import { Subtitle } from '../../../../Common/global/styles/Header/Header.styles';

const PublishingHeader: React.FC<{ subtitle: string }> = ({ subtitle }): JSX.Element => (
  <MiddleSection>
    <Subtitle>{subtitle}</Subtitle>
  </MiddleSection>
);

export default PublishingHeader;
