import { Icon } from '@adsk/alloy-react';
import React from 'react';
import { FlexContainer } from '../../../../../../Common/global/styles/Common/Common.styles';
import { MoveUpDownIcon } from '../EditInputs.styles';

interface MoveUpDownProps {
  disabledUp: boolean;
  disabledDown: boolean;
  onClickUp: React.MouseEventHandler<SVGSVGElement>;
  onClickDown: React.MouseEventHandler<SVGSVGElement>;
}
export const MoveUpDown: React.FC<MoveUpDownProps> = ({
  disabledUp,
  disabledDown,
  onClickDown,
  onClickUp,
}) => (
  <FlexContainer>
    <div hidden={disabledUp}>
      <MoveUpDownIcon type={Icon.TYPES.TRIANGLE_UP} fr={0} onClick={onClickUp} />
    </div>
    <div hidden={disabledDown}>
      <MoveUpDownIcon type={Icon.TYPES.TRIANGLE_DOWN} fr={0} onClick={onClickDown} />
    </div>
  </FlexContainer>
);
