import { Button, ICON_TYPES } from '@adsk/alloy-react';
import React from 'react';
import { MiddleButton, MiddleSection } from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { CompleteDialog, PublishingErrorMessageWrapper } from './Publishing.styles';
import {
  DraftTemplatePublishResult,
  MetaInfo,
  MetaInfoPath,
} from '../../../lib/interfaces/templates';

export interface PublishingFailedProps {
  draftName: string;
  draftAccount: MetaInfo;
  draftProject: MetaInfo;
  draftFolder: MetaInfoPath;
  publishResponse: DraftTemplatePublishResult | undefined;
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}

export const PublishingFailed: React.FC<PublishingFailedProps> = ({
  draftName,
  draftAccount,
  draftFolder,
  draftProject,
  publishResponse,
  handleNewTemplateClick,
  handleOpenSavedDraftsClick,
}): JSX.Element => (
  <CompleteDialog>
    <p>
      {text.publishingUnableToPublish} <strong>{draftName}</strong> {text.publishingTo}{' '}
      <strong>
        {draftAccount.name} / {draftProject.name} / {draftFolder.name}
      </strong>{' '}
      {text.publishingAtThisTime}
      <br />
      {text.publishingViewFailureLogs} {text.publishingFailureSavedAsDraft}
    </p>
    <MiddleSection>
      <MiddleButton
        icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
        size={Button.SIZES.SMALL}
        variant={Button.VARIANTS.PRIMARY}
      >
        {text.buttonViewLogs}
      </MiddleButton>
      <MiddleButton
        icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
        size={Button.SIZES.SMALL}
        onClick={handleNewTemplateClick}
      >
        {text.buttonNewProductDefinition}
      </MiddleButton>
      <MiddleButton
        icon={ICON_TYPES.BOOKMARK_FILLED}
        size={Button.SIZES.SMALL}
        onClick={handleOpenSavedDraftsClick}
      >
        {text.buttonSavedDrafts}
      </MiddleButton>
    </MiddleSection>
    <PublishingErrorMessageWrapper>
      <h3>{text.notificationPublishDraftFailed}</h3>
      <p className="error-message">{publishResponse?.errorMessage}</p>
    </PublishingErrorMessageWrapper>
  </CompleteDialog>
);
