import React from 'react';
import { OutputContainer } from './OutputsTab.styles';
import OutputRepresentations from './OutputRepresentations';
import { inventorModelOutputRepresentations } from './constants';

const InventorModelOutput: React.FC = (): JSX.Element => (
  <OutputContainer>
    <OutputRepresentations
      representations={inventorModelOutputRepresentations}
      selectedRepresentations={[]}
    />
  </OutputContainer>
);

export default InventorModelOutput;
