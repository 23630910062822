import { useState } from 'react';

interface UseMIDTabsHook {
  activeTab: string;
  handleTabClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  resetToInitialActiveTab: () => void;
}

interface UseMIDTabsArgs {
  initialActiveTab: string;
}

export const useMIDTabs = ({ initialActiveTab }: UseMIDTabsArgs): UseMIDTabsHook => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const handleTabClick = (event: React.MouseEvent<HTMLLIElement>) => {
    // If Tab is disabled, don't do anything
    const isDisabled = event.currentTarget.classList.contains('disabled');
    if (isDisabled) {
      return;
    }

    // grab the selected tab id
    const id = event.currentTarget.id;
    if (id && activeTab !== id) {
      setActiveTab(id);
    }
  };

  const resetToInitialActiveTab = () => {
    setActiveTab(initialActiveTab);
  };

  return {
    activeTab,
    resetToInitialActiveTab,
    handleTabClick,
  };
};

export default useMIDTabs;
