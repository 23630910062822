import React from 'react';
import { Column, DataGridRows } from '../Tables.types';
import TableEdit from './TableEdit';
import { SheetsSidebar } from './SheetsSidebar';
import TableDataGrid from './TableDataGrid';
import {
  ContentWrapper,
  FlexContainer,
} from '../../../../../Common/global/styles/Common/Common.styles';

interface TableViewProps {
  sheets: string[];
  dataGridRows: DataGridRows;
  columns: Column[];
  currentSheet: string;
  selectedTable: string;
  handleSelectSheet: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteClick: () => void;
  setDataGridRows: React.Dispatch<React.SetStateAction<DataGridRows>>;
}

const TableView: React.FC<TableViewProps> = ({
  sheets,
  dataGridRows,
  columns,
  currentSheet,
  selectedTable,
  handleSelectSheet,
  handleDeleteClick,
  setDataGridRows,
}): JSX.Element => (
  <>
    <TableEdit currentTable={selectedTable} handleDeleteClick={handleDeleteClick} />
    <FlexContainer>
      <SheetsSidebar
        sheets={sheets}
        currentSheet={currentSheet}
        handleSelectSheet={handleSelectSheet}
      />
      <ContentWrapper>
        <TableDataGrid
          columns={columns}
          currentSheet={currentSheet}
          dataGridRows={dataGridRows}
          sheets={sheets}
          setDataGridRows={setDataGridRows}
        />
      </ContentWrapper>
    </FlexContainer>
  </>
);

export default TableView;
