import { useContext, useState, useEffect } from 'react';
import { BlocklyState } from './BlocklyModule.types';
import DataContext from '../../context/DataStore/Data.context';
import { generateUniversalInputsBlock } from './utils';
import { valueKey, visibleKey, isfunctionAvailable, readOnlyKey, labelKey } from './constants';
import { DraftTemplateInputParameter } from '../../../lib/interfaces/templates';
import { isEmpty } from 'lodash';

// Generates blocks for universal block based on data store
const useGenerateBlocklyState = (): BlocklyState => {
  const { currentDraft } = useContext(DataContext);
  const [blocklyBlocks, setBlocklyBlocks] = useState<BlocklyState[]>([]);

  useEffect(() => {
    const generatedBlocks = currentDraft.parameters.reduce<BlocklyState[]>((acc, parameter) => {
      // We loop through each key and generate it's corresponding block when it's available
      // then we connect it to the previous block
      let parentBlock: BlocklyState = {};
      let currentBlock = parentBlock;
      Object.keys(parameter).forEach((key) => {
        //We exclude the value key because it generates conflict with the revit form
        if (isfunctionAvailable(key) && key !== valueKey) {
          const currentKey = key as keyof DraftTemplateInputParameter;

          //Don't add block if property is default
          if ([visibleKey, readOnlyKey, labelKey].includes(key)) {
            if (
              (currentKey === visibleKey && parameter[currentKey] === true) ||
              (currentKey === readOnlyKey && parameter[currentKey] === false) ||
              (currentKey === labelKey && parameter[currentKey] === '')
            ) {
              return;
            }
          }

          const newConnectingBlock = generateUniversalInputsBlock(
            { name: parameter.name, type: parameter.type },
            key,
            parameter[currentKey],
          );

          if (currentBlock.next) {
            currentBlock.next = { block: { ...newConnectingBlock } };
            currentBlock = currentBlock.next.block;
          } else {
            parentBlock = { ...newConnectingBlock };
            currentBlock = parentBlock;
          }
        }
      });

      if (!isEmpty(parentBlock)) {
        acc.push(parentBlock);
      }
      return acc;
    }, []);
    setBlocklyBlocks(generatedBlocks);
  }, [currentDraft.parameters]);
  return {
    blocks: {
      languageVersion: 0,
      blocks: blocklyBlocks,
    },
  };
};

export default useGenerateBlocklyState;
