import styled from 'styled-components';

export const BlocklyPlaceholder = styled.div`
  height: calc(100vh - ${({ theme }) => `${theme.vars.editInputsMenuHeight}px);`};
  width: 100%;
`;

export const BlocklyWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const PreviewPlaceholder = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.vars.paddingBase * 2}px `};
  height: calc(100vh - ${({ theme }) => `${theme.vars.editInputsMenuHeight}px);`};
`;

export const ControlsWrapper = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase}px 0`};
`;
