import { Button, Icon } from '@adsk/alloy-react';
import React from 'react';
import {
  SidebarComponent,
  SidebarButton,
  SidebarMenu,
  SidebarItem,
} from '../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../Common/global/text/text.json';

interface SheetsSidebarProps {
  currentSheet: string;
  sheets: string[];
  handleSelectSheet: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SheetsSidebar: React.FC<SheetsSidebarProps> = ({
  sheets,
  currentSheet,
  handleSelectSheet,
}): JSX.Element => {
  // TODO
  const handlePreviewRules = () => undefined;

  return (
    <SidebarComponent>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handlePreviewRules}
        icon={Icon.TYPES.CALCULATOR}
      >
        {text.buttonPreviewRules}
      </SidebarButton>
      <SidebarMenu>
        {sheets.map((sheet) => {
          const isSelected = currentSheet === sheet;
          return (
            <SidebarItem
              showSelection={false}
              selected={isSelected}
              key={sheet}
              label={sheet}
              name={sheet}
              onClick={handleSelectSheet}
            />
          );
        })}
      </SidebarMenu>
    </SidebarComponent>
  );
};
