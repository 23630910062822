import { Button } from '@adsk/alloy-react';
import styled from 'styled-components';

export const DraftConfigurationHeaderWrap = styled.div`
  display: flex;
  height: ${({ theme }) => `${theme.vars.draftConfigurationHeaderHeight}px`};
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const LeftSection = styled.div`
  width: 30%;
`;

export const RightSection = styled.div`
  margin-left: auto;
`;

export const HeaderButton = styled(Button)`
  margin: 0px ${({ theme }) => `${theme.vars.marginBase}px`} 0px 0px;
`;
