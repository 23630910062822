import {
  customBlocklyExtensions,
  blocklyDropdown,
  blocklyFunctionsDropdown,
  blocklyConnectingBlock,
  customBlocklyMutators,
  universalInputBlock,
  universalOutputBlock,
} from '../constants';

const DCDInputs = [
  {
    type: universalOutputBlock,
    message0: 'Input %1 . %2',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyDropdown,
      },
      {
        type: 'input_dummy',
        name: blocklyFunctionsDropdown,
      },
    ],
    inputsInline: true,
    output: null,
    colour: 230,
    tooltip: '',
    helpUrl: '',
    extensions: [customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION],
    mutator: customBlocklyMutators.INPUT_DROPDOWN_MUTATOR,
  },
  {
    type: universalInputBlock,
    message0: 'Input %1 . %2 = %3',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyDropdown,
      },
      {
        type: 'input_dummy',
        name: blocklyFunctionsDropdown,
      },
      {
        type: 'input_value',
        name: blocklyConnectingBlock,
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: 'tooltip',
    helpUrl: '',
    extensions: [customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION],
    mutator: customBlocklyMutators.INPUT_DROPDOWN_MUTATOR,
  },
];
export default DCDInputs;
