import React from 'react';
import { Button, Icon } from '@adsk/alloy-react';
import { LeftButton } from '../../../../Common/global/styles/Common/Common.styles';
import { LeftSection } from '../../../../Common/global/styles/Header/Header.styles';
import text from '../../../../Common/global/text/text.json';

interface PublishLocationScreenHeaderProps {
  handleEditTemplateClick: () => void;
}

export const PublishLocationScreenHeader: React.FC<PublishLocationScreenHeaderProps> = ({
  handleEditTemplateClick,
}) => (
  <LeftSection>
    <LeftButton onClick={handleEditTemplateClick} size={Button.SIZES.SMALL}>
      <Icon type={Icon.TYPES.ARROW_LEFT} fr={0} />
      {text.buttonEditProductDefinition}
    </LeftButton>
  </LeftSection>
);
