import { Button } from '@adsk/alloy-react';
import { Table } from '@adsk/alloy-react-table';
import styled from 'styled-components';

export const DraftTableWrapper = styled.div`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: 0 ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const DraftTemplateTableHeader = styled.div`
  display: flex;
  height: ${({ theme }) => `${theme.vars.draftHeader}px`};
  flex-direction: row;
  align-items: center;
`;

export const DraftTemplateTableSummary = styled.h3`
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  ${({ theme }) => theme.typography.heading3}
`;

export const SelectedText = styled.small`
  margin-left: auto;
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  ${({ theme }) => theme.typography.caption}
`;

export const TableHeaderButton = styled(Button)`
  margin: 0 0 0 ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const DraftSelectionTable = styled(Table)`
  border: 1px solid ${({ theme }) => `${theme.colors.charcoal300}`};
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.draftsTableHeight}px`}
  );
  ${({ theme }) => theme.typography.bodyMedium};
`;
