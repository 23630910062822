import { Column } from '@adsk/alloy-react-table';
import { DraftTableDataModel } from './DraftTable.types';
import text from '../../../Common/global/text/text.json';
import midTheme from '../../../Common/global/midTheme';

// because it is inside Main component and it has margin base on each side
// In windows, Table has padding for each column
const DRAFT_TABLE_LEFT_RIGHT_MARGINS =
  midTheme.vars.mainMargin + midTheme.vars.marginBase * 2 + midTheme.vars.paddingBase * 2;
const CHECKBOX_COLUMN_WIDTH = 52;

// for Columns width, react-table does not provide percentage values :(
// Math.round with innerWidth solution found in https://github.com/TanStack/table/issues/43
export const getColumns = (): Column<DraftTableDataModel>[] => [
  {
    id: 'templateName',
    accessor: 'templateName',
    renderHeader: () => text.draftTableColumnTemplateName,
    width: Math.round(
      (window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.2,
    ),
  },
  {
    id: 'numberOfInputs',
    accessor: 'numberOfInputs',
    renderHeader: () => text.draftTableColumnNumberOfInputs,
    width: Math.round(
      (window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.1,
    ),
  },
  {
    id: 'publishTo',
    accessor: 'publishTo',
    renderHeader: () => text.draftTableColumnPublishTo,
    width: Math.round(
      (window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.5,
    ),
  },
  {
    id: 'lastUpdated',
    accessor: 'lastUpdated',
    renderHeader: () => text.draftTableColumnlastUpdated,
    width: Math.round(
      (window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.2,
    ),
  },
];
