import { Column } from '@adsk/alloy-react-table';
import { isUndefined } from 'lodash';
import React from 'react';
import {
  ContentWrapper,
  FlexContainer,
  SidebarTitle,
} from '../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../Common/global/text/text.json';
import { TemplateInputType } from '../../../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
} from '../../../../../lib/interfaces/templates';
import BooleanInputSection from '../../../BooleanInputSection/BooleanInputSection';
import { IPropertyInfo } from '../../../iPropertyInfoSection/iPropertyInfo';
import { MultiValueListSection } from '../../../MultiValueListSection/MultiValueListSection';
import NumericInputSection from '../../../NumericInputSection/NumericInputSection';
import { ParameterInfo } from '../../../ParameterInfoSection/ParameterInfo';
import TextInputSection from '../../../TextInputSection/TextInputSection';
import { SetSelectedParameters } from '../InputsSelection/InputsSelection.types';
import { HandleSelectedInputUpdateType } from '../useInputsTab';
import DependencyRules from './DependencyRules/DependencyRules';
import useDependencyRulesDropdown from './DependencyRules/useDependencyRulesDropdown';
import {
  EditInputsContainer,
  CodeblocksButtonContainer,
  EnableDisableFormWrapper,
} from './EditInputs.styles';
import { RuleTableModel } from './EditInputs.types';
import { EditInputsSidebar } from './EditInputsSidebar/EditInputsSidebar';
import useEditInputs from './useEditInputs';
import { Button } from '@adsk/alloy-react';

interface EditInputsProp {
  selectedParameters: DraftTemplateInputParameter[];
  selectedIProperties: DraftTemplateIProperty[];
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedParameters: SetSelectedParameters;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

const EditInputs: React.FC<EditInputsProp> = ({
  selectedParameters,
  selectedIProperties,
  setIsEditStep,
  handleSelectedInputDataStoreUpdate,
}): JSX.Element => {
  const {
    selectedInput,
    selectedParameterInfo,
    selectediPropertyInfo,
    handleChangeSelectedInputs,
    handleParameterSelection,
    handleIPropertySelection,
    callInputDataStoreUpdateHandler,
    handleParameterMoveUp,
    handleParameterMoveDown,
    isCodeblocksWorkspaceUndefined,
    setCodeblocksWorkspaceToUndefined,
  } = useEditInputs({
    selectedParameters,
    selectedIProperties,
    setIsEditStep,
    handleSelectedInputDataStoreUpdate,
  });

  const {
    currentControllingInputDropdownValue,
    controllingInputDropdownItems,
    handleSelectControllingInput,
  } = useDependencyRulesDropdown({
    selectedParameterName: selectedParameterInfo?.name,
    selectedParameters,
    handleSelectedInputDataStoreUpdate,
  });
  const dependencyRuleTableData: RuleTableModel[] = [
    {
      rule: 1,
      condition: text.dependencyRuleTableTrueCondition,
      response: text.dependencyRuleTableTrueResponse,
    },
    {
      rule: 2,
      condition: text.dependencyRuleTableFalseCondition,
      response: text.dependencyRuleTableFalseResponse,
    },
  ];

  const dependencyRuleTableColumns: Column<RuleTableModel>[] = [
    {
      id: 'rule',
      accessor: 'rule',
      renderHeader: () => text.dependencyRuleTableRule,
    },
    {
      id: 'condition',
      accessor: 'condition',
      renderHeader: () =>
        `${currentControllingInputDropdownValue} ${text.dependencyRuleTableCondition}`,
    },
    {
      id: 'response',
      accessor: 'response',
      renderHeader: () =>
        `${selectedParameterInfo?.name || ''} ${text.dependencyRuleTableResponse}`,
    },
  ];

  const renderParameterComponents = (param: DraftTemplateInputParameter) => {
    switch (param.type) {
      case TemplateInputType.MultiValueNumeric:
      case TemplateInputType.MultiValueText:
        return (
          <MultiValueListSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            items={param.values}
            value={param.value}
            visible={param.visible}
            readOnly={param.readOnly}
            disableInput
          />
        );
      case TemplateInputType.Boolean:
        return (
          <BooleanInputSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            trueLabel={param.trueLabel || ''}
            falseLabel={param.falseLabel || ''}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      case TemplateInputType.Text:
        return (
          <TextInputSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            defaultValue={param.value}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      case TemplateInputType.Numeric:
        return (
          // TODO: Replace alloy TextInput from NumericInputSection due to this interface problem?
          <NumericInputSection
            defaultValue={param.value.toString()}
            // When param changes, we need a new instance of this component to be mounted
            // to avoid previous params values persisting
            // When react sees a new key, it will mount a new instance of the component
            key={`${param.name}_${param.value}`}
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            // In React, having the value prop with a value of undefined on a form element indicates
            // it being uncontrolled, meaning it does not rely on a prop for its value.
            // React would give you an error in the console if you change the value of the value
            // prop on a form element to undefined (switching from controlled to uncontrolled).
            // In our case, if undefined, the value of the previous component persists,
            // React thinks as it hasn't been updated, causing a bug. So, value || empty string
            // https://github.com/JedWatson/react-select/issues/3066#issuecomment-643000641
            min={isUndefined(param.min) ? '' : param.min}
            max={isUndefined(param.max) ? '' : param.max}
            increment={isUndefined(param.increment) ? '' : param.increment}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      default:
        <></>;
    }
  };

  return (
    <>
      {!isCodeblocksWorkspaceUndefined && (
        <CodeblocksButtonContainer>
          <Button variant={Button.VARIANTS.PRIMARY} onClick={setCodeblocksWorkspaceToUndefined}>
            {text.discardRules}
          </Button>
        </CodeblocksButtonContainer>
      )}
      <EnableDisableFormWrapper disabled={!isCodeblocksWorkspaceUndefined}>
        <FlexContainer>
          <EditInputsSidebar
            selectedInput={selectedInput}
            selectedParameterInfo={selectedParameterInfo}
            selectediPropertyInfo={selectediPropertyInfo}
            handleChangeSelectedInputs={handleChangeSelectedInputs}
            handleParameterSelection={handleParameterSelection}
            handleIPropertySelection={handleIPropertySelection}
            handleParameterMoveUp={handleParameterMoveUp}
            handleParameterMoveDown={handleParameterMoveDown}
            isFormDisabled={!isCodeblocksWorkspaceUndefined}
          />
          <ContentWrapper>
            {(selectedParameterInfo && (
              <>
                <SidebarTitle>
                  {text.editInputsTitle} <strong>{selectedParameterInfo.name}</strong>
                </SidebarTitle>
                <EditInputsContainer>
                  <ParameterInfo
                    parameter={selectedParameterInfo}
                    callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
                  />
                  {renderParameterComponents(selectedParameterInfo)}
                  <DependencyRules
                    items={controllingInputDropdownItems}
                    value={currentControllingInputDropdownValue}
                    dependencyRuleTableColumns={dependencyRuleTableColumns}
                    dependencyRuleTableData={dependencyRuleTableData}
                    handleSelectControllingInput={handleSelectControllingInput}
                  />
                </EditInputsContainer>
              </>
            )) ||
              (selectediPropertyInfo && (
                <>
                  <SidebarTitle>
                    {text.editInputsTitle} <strong>{selectediPropertyInfo.name}</strong>
                  </SidebarTitle>
                  <EditInputsContainer>
                    <IPropertyInfo
                      iProperty={selectediPropertyInfo}
                      callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
                    />
                  </EditInputsContainer>
                </>
              ))}
          </ContentWrapper>
        </FlexContainer>
      </EnableDisableFormWrapper>
    </>
  );
};

export default EditInputs;
