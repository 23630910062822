import {
  Button,
  ICON_TYPES,
  NOTIFICATION_STATUSES,
  TextInput,
  useNotifications,
} from '@adsk/alloy-react';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAsyncPostData } from '../../../Common/global/hooks/http/hooks';
import logger from '../../../Common/global/logger';
import { SpinnerForButton } from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { DraftTemplate } from '../../../lib/interfaces/templates';
import { saveDraft } from '../../../lib/utils/drafts';
import DataContext from '../../context/DataStore/Data.context';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../types';
import {
  DraftConfigurationHeaderWrap,
  HeaderButton,
  LeftSection,
  RightSection,
} from './DraftConfigurationHeader.styles';

interface DraftConfigurationHeaderProps {
  handlePublishClick: () => void;
}

const DraftConfigurationHeader: React.FC<DraftConfigurationHeaderProps> = ({
  handlePublishClick,
}): JSX.Element => {
  const { currentDraft, setCurrentDraft, setCurrentDraftName } = useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const readyToPublish =
    tabProgress[TABS.SOURCE_CONTENT] === TabProgress.COMPLETED &&
    tabProgress[TABS.INPUTS] === TabProgress.COMPLETED &&
    tabProgress[TABS.OUTPUTS] === TabProgress.COMPLETED;
  const [draftName, setDraftName] = useState(currentDraft.name);
  const {
    postFn: wrappedSaveDraft,
    loading: saveDraftLoading,
    error: saveDraftError,
  } = useAsyncPostData<DraftTemplate>(saveDraft);
  const { showNotification } = useNotifications();

  useEffect(() => {
    if (saveDraftError) {
      logger.error('Error: Unable to save draft.', saveDraftError);
      showNotification({
        message: text.notificationSavedDraftFailed,
        status: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [saveDraftError, showNotification]);

  const handleSaveDraft = async () => {
    const savedDraft = await wrappedSaveDraft(currentDraft);
    if (savedDraft) {
      setCurrentDraft(savedDraft);
      showNotification({
        message: text.notificationSavedDraftSuccess,
        status: NOTIFICATION_STATUSES.SUCCESS,
      });
    }
  };

  const debounceUpdateCurrentDraft = debounce((value: string) => setCurrentDraftName(value), 500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangedDebounced = useCallback(
    debounceUpdateCurrentDraft,
    [], // Creating it only once initially.
  );

  const handleDraftTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftName(event.target.value);
    onChangedDebounced(event.target.value);
  };

  useEffect(() => {
    setDraftName(currentDraft.name);
  }, [currentDraft.name]);

  return (
    <DraftConfigurationHeaderWrap>
      <LeftSection>
        <TextInput
          data-testid="draft-template-name-input"
          icon={ICON_TYPES.PENCIL_FILLED}
          placeholder={text.draftConfigurationHeaderUntitled}
          disabled={!currentDraft.assembly}
          value={draftName}
          onChange={handleDraftTemplateNameChange}
        />
      </LeftSection>
      <RightSection hidden={!draftName}>
        <HeaderButton onClick={handleSaveDraft} data-testid="save-draft-button">
          {text.draftConfigurationHeaderSaveDraft} {saveDraftLoading && <SpinnerForButton />}
        </HeaderButton>
        <HeaderButton
          disabled={!readyToPublish}
          onClick={handlePublishClick}
          data-testid="publish-button"
          variant={Button.VARIANTS.PRIMARY}
        >
          {text.draftConfigurationHeaderPublish}
        </HeaderButton>
      </RightSection>
    </DraftConfigurationHeaderWrap>
  );
};

export default DraftConfigurationHeader;
