import { Selected } from '@adsk/alloy-react-dropdown';
import { Table } from '@adsk/alloy-react-table';
import styled from 'styled-components';

export const InputsHeaderWrapper = styled.div`
  height: ${({ theme }) => `${theme.vars.largeButtonHeight}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const InputsFilterRenderItem = styled(Selected)`
  display: flex;
  align-items: center;
`;

export const InputsFilterDropDown = styled.div`
  margin-left: auto;
  padding-right: ${({ theme }) => `${theme.vars.paddingBase}px`};
`;

export const InputsSelectionTable = styled(Table)`
  border: 1px solid ${({ theme }) => `${theme.colors.charcoal300}`};
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.editInputsTableHeight}px`}
  );
  ${({ theme }) => theme.typography.bodyMedium};
`;
